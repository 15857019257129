import { Component, OnInit, Input } from '@angular/core';
import { HexagonModel } from '../../model/hexagonModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss']
})

/**
  *This component is a shared component to create a hexagon
  */
export class HexagonComponent implements OnInit {

  /**
  *Takes as input a model of type hexagonModel
  */
  @Input() hexData:HexagonModel;

  /**
   * @constructor function 
   * @param router Router service that will get injected into this component   
   */
  constructor(private router:Router) {   
  }

  /**
   * This function will gets executed on load of this component.
   */
  ngOnInit() {
  }

  /**
   * Executes when mouse enters the hexagon area
   */
  mouseEnter(event){
    this.hexData.hover=true;
  }

  /**
   * Executes when mouse leaves the hexagon area
   */
  mouseLeave(event){
    this.hexData.hover=false;
  }

}
