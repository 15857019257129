import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';
import { InitialResponseDTO } from '../model/initial-response.dto';
import { AppState } from './app-state';

/**
 * @class SelfModeGuardService
 * @author Saurabh bajpai
 */
@Injectable()
export class SelfModeGuardService implements CanActivate {
/**
 * patron logged in or not
 */
    patronLoggedIn: boolean;
/**
 * 
 * @param router Router service wiil be injected into the component
 * @param platformId inject Platfor ID
 * @param appStateService AppStateService wiil be injected into the component
 */
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object,
        private appStateService: AppStateService
    ) {
        this.appStateService.appStateModel.subscribe((state: AppState) => {
            if (state.patronLoggedIn) {
                this.patronLoggedIn = true;
            } else {
                this.patronLoggedIn = false;
            }
        });
    }

    /**
     * if, userType == self 
     * if, patronIdentificationType is of with-card
     *      then return true
     * else if, without-card
     *      then, check whether patron has logged-in or not
     *      if, patronLoggedIn = true, then return true
     *      else, return false
     */
    /**
     * This service used to decied weater used go to manual mode or automatic mode
     */
    canActivate(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            let userType = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userType);
            if (userType == AppConstants.userType.self) {
                let initialResp: InitialResponseDTO = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings);
                if (initialResp.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.barcode) {
                    //with-out card
                    if (this.patronLoggedIn) {
                        return true;
                    } else {
                        this.navigateToDashboard();
                        return false;
                    }
                } else {
                    //with-card
                    return true;
                }
            } else {
                this.navigateToDashboard();
                return false;
            }
        }
    }
/**
 * navigate to dashboard
 */
    navigateToDashboard() {
        this.router.navigate([AppConstants.routes.dashboard]);
    }

}
