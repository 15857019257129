import { ViewChild, Component, OnChanges, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import * as _moment from 'moment';

const moment =  _moment;
/**
 * Provides view for material datepicker
 */
@Component({
  selector: 'ls-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }
  ]
})
export class DatePickerComponent implements ControlValueAccessor, OnChanges, Validator {
  /**
   * date filter
   */
  @Input('dateFilter') dateFilter;
  /**
   * Datefield Placeholder
   */
  @Input('placeholder') placeholder: string;
  /**
   * Start view of the Datepicker
   */
  @Input('start-view') startView: string = 'month';
  /**
   * Default start date
   */
  @Input('start-at') startDate: Date;
  /**
   * Minimum Date
   */
  @Input('min') minDate: Date;
  /**
   * Maximum Date
   */
  @Input('max') maxDate: Date;
  /**
   * Activate Touch UI Flag
   */
  @Input('touch-ui') touchUI: boolean;
  /**
   * Disable the field
   */
  @Input('disabled') disabled: boolean;
  /**
   * Field is Required flag
   */
  @Input('required') isRequired: boolean;

  /**
   * Value of the field
   */
  @Input('value') value: Date;
  /**
   * Open datepicker on focus
   */
  @Input() openOnFocus: boolean
  /**
   * Add 12 Hours in date time Flag
   */
  @Input() add12Hours: boolean
  /**
   * Display datepicker overlay flag.
   */
  @Input() datePickerOverlay: boolean
  /**
   * Makes textfield in date editable
   */
  @Input() editableDate: boolean

  @Output('dateInput') dateInput = new EventEmitter<any>();
  /**
   * Element reference of the date picker.
   */
  @ViewChild('picker', {static: true}) picker;
  /**
   * Blur event emitter on field change.
   */
  @Output('blur') blur = new EventEmitter<void>()

  /**
   * Constructor
   */
  constructor() {
    this.validator = this.rangeValidator();

  }

  /**
   * FormControl Implementation
   */
  validationFunction: Function;
  /**
   * FormControl Implementation
   */
  validator: ValidatorFn;
  /**
   * @ignore
   * @param changes 
   */
  ngOnChanges(changes) {
    // if (changes.minDate || changes.maxDate) {
    //   this.validationFunction = createDatePickerValidator(this.maxDate, this.minDate);
    // }
  }

  /**
   * Runs validator on the input.
   * @param c 
   */
  validate(c: UntypedFormControl) {
    // return this.validationFunction(c);
    return this.validator(c);
  }
  /**
   * Range Validator function
   */
  // rangeValidator(): ValidatorFn {
  //   return (c: FormControl) => {
  //     if (this.minDate === undefined && this.maxDate === undefined) {
  //       return null;
  //     }
  //     else {
  //       if (this.minDate) {
  //         let minError = {
  //           minError: {
  //             valid: false
  //           }
  //         };
  //         if (c.value < this.minDate) {
  //           return minError;
  //         }
  //       }
  //       if (this.maxDate) {
  //         let maxError = {
  //           maxError: {
  //             valid: false
  //           }
  //         };
  //         if (c.value > this.maxDate) {
  //           return maxError;
  //         }
  //       }
  //     }
  //   }
  // }

  editDate(event){
    if(!this.editableDate)
    event.preventDefault()
  }

  rangeValidator(): ValidatorFn {
    return (c: UntypedFormControl) => {
      if (this.minDate === undefined && this.maxDate === undefined) {
        return null;
      }
      else {
        if (this.minDate && c.value)  {
          let minError = {
            minError: {
              valid: false
            }
          };
          if (c.value < this.minDate) {
            return minError;
          }
        }
        if (this.maxDate) {
          let maxError = {
            maxError: {
              valid: false
            }
          };
          if (c.value > this.maxDate) {
            return maxError;
          }
        }
      }
    }
  }

  /**
   * 
   * FormControl Implementation
   * @param value 
   */
  writeValue(value: any) {
    if (value !== undefined)
      this.value = value;
  }
  /**
   * FormControl Implementation
   */
  propagateChange = (_: any) => { };

  /**
   * FormControl Implementation
   * @param fn 
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * FormControl Implementation
   */
  registerOnTouched() { }
  /**
   * Date field change event handler.
   * @param $event 
   */
  onInput($event) {
    if (this.add12Hours) {
      let d: Date = new Date($event.value.toDate())
      d = new Date(d.getTime() + 12 * 60 * 60 * 1000)
      this.dateInput.emit(d)
    } else {
      let d: Date = new Date($event.value.toDate())
      this.dateInput.emit(d)
    }
  }
  /**
   * Datefield changes handler
   * @param $event 
   */
  onChange($event) {
    if ($event.value !== undefined && $event.value !== null && $event.value !== '') {
      this.propagateChange(new Date($event.value));
    } else {
      this.propagateChange(undefined);
    }

  }

  /**
   * Date picker open trigger.
   */
  openDatePicker(): void {
    this.picker.open();
  }

  /**
   * Field Blur emitter
   */
  onBlur() {
    this.blur.emit()
  } 

  /**
   * Open's the field on field focus
   * @param picker 
   */
  onFocus(picker) {
    if (this.openOnFocus) {
      picker.open()
    }
  }
  /**
   * Click on the overlay handler
   */
  overlayClicked() {
    if (!this.disabled) {
      this.openDatePicker()
    }
  }
}
/**
 * Range validator for the date field.
 * @param maxDate 
 * @param minDate 
 */
export function createDatePickerValidator(maxDate, minDate) {
  return function validateDatePicker(c: UntypedFormControl) {
    let maxError = {
      maxError: {
        given: c.value,
        max: maxDate
      }
    };
    let minError = {
      minError: {
        given: c.value,
        min: minDate
      }
    };
    let validError = {
      validError: {
        given: c.value,
      }
    };
    if (c.value === null || c.value === undefined || c.value === '') {
      return validError;
    }
    else if (c.value > maxDate) {
      return maxError;
    }
    else if (c.value < minDate) {
      return minError;
    }
    else {
      return null;
    }
  }
}