import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
/**
 * Provides view for Material checkbox 
 */
@Component({
  selector: 'ls-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
  , providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent implements OnInit {
  /**
   * Visibility Handler of the checkbox 
   */
  @Input('visible') visible: boolean;
  /**
   * Flag for disable the field
   * @default false
   */
  @Input('disabled') disabled: boolean;
  /**
   * Default Value of the checkbox 
   */
  @Input('value') value: any;
  /**
   * Checkbox state event emitter.
   */
  @Output() updatedStatus = new EventEmitter<boolean>();
  /**
   * @ignore
   */
  status;
  /**
   * FormControl Implementation 
   * @param value 
   */
  writeValue(value: any) {
    if(value == 0){
      this.value = false;
    } else if (value !== undefined) {
      this.value = value;
    }
  }
  /**
   * FormControl Implementation 
   */
  propagateChange = (_: any) => { };
  /**
   * FormControl Implementation 
   */
  propagateTouch = (_: any) => { };
  /**
   * FormControl Implementation 
   * @param fn
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
    this.propagateTouch = fn;
  }
  /**
   * FormControl Implementation 
   * @param fn
   */
  registerOnTouched(fn) {
    this.propagateTouch = fn
  }
  /**
   * Constructor
   */
  constructor() { }
  /**
   * Checkbox change event.
   */
  onChange() {
    this.value = !this.value;
    this.updatedStatus.emit(this.value);
    this.propagateChange(this.value)
  }
  /**
   * @ignore
   */
  ngOnInit() {
    if (this.value == 0) {
      this.value = false;
    }
  }



}
