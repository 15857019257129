import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { ManualCirculationRequestDto } from '../model/manualCirculationRequestDto.model';

/**
 * @class SelfPatronActionsService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class SelfPatronActionsService {
  /**
   * Manual circulation request dto
   */
  public manualCirculationRequestDto: ManualCirculationRequestDto[] 
  /**
   * 
   * @param httpService HttpService wiil be injected into the component
   */
  constructor(
    private httpService : HttpService,
  ) { }
  /**
   * To checkout the items
   * @param body 
   * @param memberId 
   * @param callback 
   */
  patronCheckOut(body,memberId,callback: (data) => void){
    let url = environment.service_url + '/api/kiosk/checkOut';
    let requestParams = new Map().set('patronId', memberId);
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    },(error) => {
      console.log(error);
    });
  }
  /**
   * To checkin the items
   * @param body 
   * @param memberId 
   * @param callback 
   */
  patronCheckIn(body,memberId, callback: (data) =>void){
    let url = environment.service_url + '/api/kiosk/checkIn';
    let requestParams = new Map().set('patronId', memberId);
    this.httpService.post(url , body , requestParams , (resp) =>{
      callback(resp);
    },(error) => {
      console.log(error);
    });
  }
  /**
   * To checkin the items without member ID
   * @param body 
   * @param callback 
   */
  patronCheckInWithoutMember(body, callback: (data) =>void){
    let url = environment.service_url + '/api/kiosk/withoutMember/checkIn'; 
    let requestParams = new Map();
    this.httpService.post(url , body , requestParams , (resp) =>{
      callback(resp);
    },(error) => {
      console.log(error); 
    });
  }
  /**
   * To renew the items
   * @param body 
   * @param memberId 
   * @param fromAccount 
   * @param isCorporate 
   * @param callback 
   */
  patronRenew(body,memberId,fromAccount:boolean,isCorporate: boolean, callback: (data) =>void){
    let url = environment.service_url + '/api/kiosk/renew';
    let requestParams = new Map();
    requestParams.set("fromAccount",fromAccount).set("isCorporate",isCorporate).set("patronId", memberId);
    this.httpService.post(url , body , requestParams , (resp) =>{
      callback(resp);
    },(error) => {
      console.log(error);
    });
  }
  patronRenewWithoutMember(body, fromAccount:boolean, isCorporate:boolean, callback: (data) => void){
    let url= environment.service_url + '/api/kiosk/withoutMember/renew';
    let requestParams = new Map();
    requestParams.set("fromAccount", fromAccount).set("isCorporate", isCorporate);
    this.httpService.post(url, body , requestParams , (resp) => {
      callback(resp);
    },(error) => {
      console.log(error);
    })
  }
/**
 * To get the details of checked out items
 * @param memberId 
 * @param callback 
 */
  selfMyAccount(memberId, callback: (data) => void){
    let url = environment.service_url + '/api/patron/checkouts';
    let requestParams = new Map().set('patronId', memberId);
    this.httpService.get(url, requestParams ,(resp) =>{
      callback(resp);
    },(error) => {
      console.log(error);
    })
  }


  
}
