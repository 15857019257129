import { Component, OnInit } from '@angular/core';
import { Time } from '@angular/common';
import { AppConstants } from 'src/app/common/constants/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * This component renders the footer component in the application
 */
export class FooterComponent implements OnInit {
  // libraryName = "Gyanodaya";
  // libraryBuild = "1.0.2048.32587";
  /**
   * holds the current time
   */
  currentTime : Date;
  /**
   * reference of appConstant in the app
   */
  constants = AppConstants;

  /**
   * @constructor function
   */
  constructor() { }

  /**
   * This function will gets executed on load of this component.
   */
  ngOnInit() {
    this.currentTime = new Date(new Date().getTime() );
    setInterval(() => {
    this.currentTime = new Date(new Date().getTime() );
    }, 1000)
  }

}
