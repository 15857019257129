import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable , throwError} from 'rxjs';
import { catchError} from 'rxjs/operators';
import { LoaderService } from './loader.service';

/**
 * @class FingerprintLayerService
 * @author Saurabh bajpai
 */
@Injectable()
export class FingerprintLayerService {
  /**
   * Store fingerprint request url
   */
  // fingerprint_Url = environment.fingerprint_url; 
  /**
   * 
   * @param http Http service will be injected into the component
   * @param loaderService LoaderService will be injected into the component
   */
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }
/** 
 * This service used to get fingerprint response
 * @param callback function
 * @param error show error
 * @param showLoader To show loader
 */
  fingerprintLayerResponse(callback: (data: any) => void, error?:any ,showLoader:boolean = false) {
    let body = ''; 
    let requestParams = new Map();
    let fingerprint_Url = environment.fingerprint_url;
    this.http.post(fingerprint_Url, body, { headers: { 'no-auth': 'true' } }) 
    .pipe(catchError(err => {
      if(showLoader){
        this.loaderService.hide();
      }
      error(err)
      return throwError (err || "some error occured");
    })).subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      callback(resp);
    }, err => {
      callback(err);
    });
  }

}
