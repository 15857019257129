import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { LsFileUploadService } from '../ls-file-upload.service';
import * as $ from 'jquery';

/**
 * File upload component
 */
@Component({
  selector: 'ls-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  /**
   * data check input
   */
  @Input('checkData') checkData
  /**
   * The constructor
   * @param element 
   */
  public constructor(private element: ElementRef) { }
  /**
   * message to be displayed
   */
  message;
  /**
   * Drag variable
   */
  drag = true;
  /**
   * return the file
   */
  getFile;
  /**
   * Drop width of file uploader
   */
  @Input("dropWidth") width
  /**
   * Drop height of file uploader
   */
  @Input("dropHeight") height
  /**
   * response of the file upload
   */
  @Input("response") response;
  /**
   * files to be uploaded
   */
  @Output("files") files = new EventEmitter();
  /**
   * uploader type
   */
  @Input('customUploader') customUploader
  /**
   * allow multiple file upload
   */
  @Input("multiple") multiple;
  /**
   * type of the file
   * @example pdf, docx etc
   */
  @Input() fileType: string;
  /**
   * file upload boarder
   */
  @Input() border_not_required: boolean;
  /**
   * current selected file
   */
  selectedFiles;
  /**
   * list of files
   */
  fileList;
  /**
   * @ignore
   */
  ngOnInit() {

  }
  /**
   * On file upload click
   */
  onClick() {
    this.element.nativeElement.querySelector("#lsfileupload").click();
  }
  /**
   * upload a file 
   */
  upload() {
    let files;
    if (this.customUploader == 3)
      files = this.fileList;
    else
      files = this.element.nativeElement.querySelector('#lsfileupload').files;
    this.files.emit(files);
    $('#lsfileupload').prop('value', '')
  }
  /**
   * upload file on drag
   * @param value 
   */
  uploadDragFiles(value) {
    this.fileList = value;
    this.upload();
  }
}


