import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef , ElementRef , Input , Directive, ViewChildren, ViewChild, AfterViewInit, HostListener} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/common/constants/app.constants';
import { SelfModePatronInfoWithoutCardService } from 'src/app/common/service/self-mode-patron-info-without-card.service';
// import {PatronStatusRequestModel} from 'src/app/common/model/patronStatusRequest.model'
import { Operations } from 'src/app/common/model/operations.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { SelfPatronResponseDTO } from 'src/app/common/model/selfPatronResponse.dto';
import { StartupService } from 'src/app/common/service/startup.service';
import { AppStateService } from 'src/app/common/service/appState.service';
import { Subscription } from 'rxjs';
import { SettingsDto } from 'src/app/common/model/settings-dto';
import { InitialResponseDTO } from 'src/app/common/model/initial-response.dto';
import { AppState } from 'src/app/common/service/app-state';
import { SelfPatron } from 'src/app/common/model/selfPatron.dto';
import { ReuseDataService } from '../../reuse-data.service';
import Keyboard from 'simple-keyboard';
import * as shajs from 'sha.js';

/**
 * @class BarcodeLoginComponent
 * @author Saurabh Bajpai
 */

@Component({
  selector: 'app-barcode-login',
  templateUrl: './barcode-login.component.html',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './barcode-login.component.scss'],
})
// @Directive({
//   selector: '[focusInput]'
// })
// @Directive({selector: '[focusMe]'})

/**This component used to verify user manually in self mode only when manual mode is on in global app setting */
export class BarcodeLoginComponent implements OnInit {
/**Form group for patron id and password */
  barcodeForm: UntypedFormGroup;
  /**variable of type number */
  barcode: number;
  /**
   * 
   */
  selfIdentificationModes: number;
  /**
   * Instanc of class SelfPatronResponseDTO used to store patron details
   */
  selfPatronInfoWithoutCard: SelfPatronResponseDTO = new SelfPatronResponseDTO();
  /**
   * Instance of class InitialResponseDTO
   */
  initialResponseData: InitialResponseDTO;
  /**
   * local instance of global app state
   */
  appState: AppState = new AppState();
  /**
   * subscribe app state
   */
  appStateSubscription;
  /** 
   * used to show error message
   */
  errorMsg: string;
  /**
   * To store the input value(but did not used it currently)
   */
  value = "";
  /**
   * Instance of simple keyboard
   */
  keyboard: Keyboard;
  /**
   * open and close keyboard on he click on keyboard icon
   */
  openKeyboard: boolean = false;
  /**
   * Input value in user id text field
   */
  userValue: string = '';
  /**
   * Input value in password text field
   */
  pwdValue: string = '';
  /**
   * Select the text field
   */
  selectUserInput: boolean = true;
  /**
   * To know on which field we clicked
   */
  buttonClicked : boolean = false;
  /**
   * unused
   */
  onlyone: number = 1;
  /**
   * used to handle the input values in userId and password fields
   */
  preventEvent: boolean;
  /**
   *  Used to prevent making of new instance of the keyboard while we click on the same field
   */
  previousEvent: any;
  
  // selectUser: any;
  // @ViewChild('selectUser') selectUser: ElementRef;
  // @Input('focusMe') isFocused: boolean;
/**
 * @constructor function
 * @param fb Form Builder service will be injected into the component
 * @param selfModePatronInfoWithoutCardService SelfModePatronInfoWithoutCardService will be injected into the component 
 * @param router Router will injected into the component
 * @param activatedRoute activatedRoute will be injected into the component
 * @param appStateService AppStateService will be injected into the component
 * @param reuseDataService ReuseDataService will be injecte into the component
 */
  constructor(
    private fb: UntypedFormBuilder,
    private selfModePatronInfoWithoutCardService: SelfModePatronInfoWithoutCardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appStateService: AppStateService,
    private reuseDataService: ReuseDataService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ) {
    this.barcodeForm = this.fb.group({
      userId: ['', [Validators.required, Validators.maxLength(16)]],
      password: ['', [Validators.required]]
    })
  } 

 selectedInput

 ngAfterViewInit(){
    this.keyboard = new Keyboard({
    onChange: input => this.onChange(input),
    onKeyPress: button => this.onKeyPress(button),
    inputName: "input1",
    maxLength: {
      input1: 16,
    }
  });
  document.querySelectorAll(".input").forEach(input => {
    input.addEventListener("focus", this.onInputFocus);
    input.addEventListener("input", this.onInputChange);
  });
 }
 
 onInputFocus = (event) => {
   this.selectedInput = `#${event.target.id}`;

   this.keyboard.setOptions({
     inputName: event.target.id
   });
  //  console.log(this.selectedInput)
 }

 onInputChange = (event) => {
   this.keyboard.setInput(event.target.value, event.target.id);
 }

 onChange = (input) => { 
  //  console.log("Input changed", input);
   document.querySelector(this.selectedInput || ".input").value = input;
   if(this.selectedInput == "#input1"){
    // console.log("user");
    this.userValue = input
    this.barcodeForm.patchValue({'userId':  this.userValue})
   }else if(this.selectedInput == "#input2"){
    // console.log("password");
    this.pwdValue = input;
    this.barcodeForm.patchValue({'password': this.pwdValue})
   }else{
    this.userValue = input
    this.barcodeForm.patchValue({'userId':  this.userValue})
   }
 }
 
 onKeyPress = (button) => {
  //  console.log("Button Pressed", button);
   if(button === "{lock}" || button === "{shift}"){
    this.handleShiftButton(); 
  }else if(button === "{enter}"){
    if (!this.initialResponseData.patronPasswordEnable) {
          this.patronLogin1(event);
          }else if(this.initialResponseData.patronPasswordEnable){
          this.patronLogin(event);
          }
  }
   
 }                
 handleShiftButton = () => {
  let currentLayout = this.keyboard.options.layoutName;
  let shiftToggle = currentLayout === "default" ? "shift" : "default";

  this.keyboard.setOptions({
    layoutName: shiftToggle
  })
 }

















  // @HostListener('keyboardClick')
  // @ViewChildren('selectUser') vc: ElementRef;
  // @ViewChildren('selectUser') selectUser 

  /**
   * This method gets executed on the loading of the component and its used here to handle keyboard functionalities.
   */
  // ngAfterViewInit(){
    
  //  this.keyboard = new Keyboard({
  //     onChange: input =>  this.onChange(input),
  //     onKeyPress: button =>  this.onKeyPress(button)
  //   });
  //   this.selectUser.first.elementRef.nativeElement.getElementsByTagName('input')[0].focus()  
  
  //  }
  /**
   * implementation of onChange method 
   * This method gets executed on input the data in any text field
  */ 
  // onChange = (input: string) => {  
  //   // console.log(input) 
  //   if(this.selectUserInput){
  //     if(!this.preventEvent){ 
  //       this.userValue = this.barcodeForm.controls['userId'].value;
  //       this.userValue =this.userValue +  input.charAt(input.length - 1) ;
  //     }else{
  //       this.userValue = input;
  //     }
      
  //       this.barcodeForm.patchValue({'userId':  this.userValue})
      
  //   }else if(!this.selectUserInput){
  //     if(!this.preventEvent){
  //       this.pwdValue = this.barcodeForm.controls['password'].value;
  //       this.pwdValue = this.pwdValue +  input.charAt(input.length - 1);
  //     }else{
  //       this.pwdValue = input; 
  //     }
  //     this.barcodeForm.patchValue({'password':  this.pwdValue})
  //   } 
    
   
    // this.barcodeForm.patchValue({'password': this.value})    
  //   console.log("input Changed", input);
  // };
  
  // log(event){
  //   this.selectUserInput = event;
  //   // if(this.onlyone == 1 || this.onlyone == 2){
  //   //   this.keyboard.clearInput();
  //   //   this.onlyone = this.onlyone + 1;
  //   // }
  //   if(this.previousEvent != event){
  //     console.log(this.barcodeForm.controls['userId'].value)
  //     // this.userValue = this.barcodeForm.controls['userId'].value
   
  //     if(!event.compose ){
  //       console.log("its working")
  //     this.keyboard = new Keyboard({
  //       onChange: input =>  this.onChange(input),
  //       onKeyPress: button =>  this.onKeyPress(button)

  //     });
  //   }

  //   }
    // console.log(event);
    // this.previousEvent = event;
    // if(this.buttonClicked){

    //   this.openKeyboard = false;
    //   setTimeout(()=>{
      
    //     this.openKeyboard = true;
            
    //   },0)

    // }

  // }
  /**
   * This method is triggerd when we press keys
   */
  // onKeyPress = (button: string) => {   
    // this.cdr.detectChanges()
    // this.preventEvent = false;
  
    // console.log("button pressed", button)
    // if(button === "{shift}" || button === "{lock}")
    // { 
    //   this.handleShift();
    // }else if(button === "{enter}"){
    //   if (!this.initialResponseData.patronPasswordEnable) {
    //     this.patronLogin1(event);
    //     }else if(this.initialResponseData.patronPasswordEnable){
    //     this.patronLogin(event);
    //     }
    // }else if(button === "{bksp}"){
    //   this.preventEvent = true;
    //   console.log(this.pwdValue);
    //   if(this.previousEvent == 1){
    //     this.userValue = this.barcodeForm.controls['userId'].value;
    //     this.userValue =  this.userValue.slice(0 , -1);
    //     this.barcodeForm.patchValue({'userId':  this.userValue})
    //   }else {
    //     this.pwdValue = this.barcodeForm.controls['password'].value;
    //     this.pwdValue = this.pwdValue.slice(0 , -1);
    //     this.barcodeForm.patchValue({'password':  this.pwdValue})

    //   }
    //   console.log(this.userValue);
      //used end
      // this.barcodeForm.patchValue({
      //   userId : this.barcodeForm.controls['userId'].value,
      // })
      // this.barcodeForm.
      // this.barcodeForm.patchValue({
      //   'userId' : this.userValue
      // })
  //   }
  // };
  /**
           * handle the shifr button;
   */
  //preivious uses
  // handleShift = () => {
  //   let currentLayout = this.keyboard.options.layoutName;
  //   let shiftToggle = currentLayout === "default" ? "shift" : "default"

  //   this.keyboard.setOptions({
  //     layoutName: shiftToggle
  //   })
  // }

/**This method gets executed into the component on the load of this component */
  ngOnInit() {
    this.appStateSubscription = this.appStateService.appStateModel.subscribe((state) => {
      this.appState = state;
    });
    if (window.localStorage.getItem(AppConstants.localStorageItems.initialSettings)) {
      this.initialResponseData = JSON.parse(window.localStorage.getItem(AppConstants.localStorageItems.initialSettings));
    }
    // console.log(this.initialResponseData);
    // console.log(this.initialResponseData.selfMemberIdentificationMode);

    // if(this.isFocused){
    //   this.hostElement.nativeElement.focus();
    // }
  }

/**
 * This method used to verify patron user id and respectice password on the submit of the  barcode form
 * @param event click event
 */
  patronLogin(event) {
    if (this.barcodeForm.controls['userId'].valid && this.barcodeForm.controls['password'].valid) {
      //console.log("valid form");
      let strongPassword= this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings).strongPassword;
      //console.log(strongPassword);
      let password;
      let body;
      if(strongPassword){
        password = this.barcodeForm.controls['password'].value;
      }
      else{
        password = this.barcodeForm.controls['password'].value.toUpperCase();
      }
      let sha1Password = shajs('sha1').update(password).digest('hex');
      
      body = {
        'memberId': this.barcodeForm.controls['userId'].value,
        'password': sha1Password,
        'isKiosk': true,
        'isCorporate': false,
        'tagId': "",
        'processType': Operations.verify_item,
        'generateOTP': this.initialResponseData.otpEnable
      }

      this.selfModePatronInfoWithoutCardService.patronVerification(body, (data) => {
        if (data && data.data) {
          //console.log("data", data.data);
          let patronData: SelfPatron = new SelfPatron();
          patronData.barcode = true;
          patronData = data.data;
          this.reuseDataService.updateSelfPatronState(patronData );
          //successful patron login
          this.appState.patronLoggedIn = true;
          this.appStateService.update(this.appState);
          this.router.navigate([AppConstants.routes.self], { relativeTo: this.activatedRoute });
        } else {
          this.appState.patronLoggedIn = false;
          this.appStateService.update(this.appState);
          this.errorMsg = data.message;
          //console.log(this.errorMsg);
        }
      });
    }else{
      if(this.barcodeForm.value.userId == "" && this.barcodeForm.value.password == ""){
        this.errorMsg = "Please Enter Login Credentials"
      }
      else if(this.barcodeForm.value.userId == ""){
        this.errorMsg = "Please Enter User Id"
      }else if(this.barcodeForm.value.password == ""){
        this.errorMsg = "Please Enter Password"
      }
    }
  }
/**
 * This method used to verify patron id on the submit of the barcode form
 * @param event click event
 */
  patronLogin1(event){

    if (this.barcodeForm.controls['userId'].valid) {
      //console.log("valid form");
      let body;
      body = {
        'memberId': this.barcodeForm.controls['userId'].value,
        'password': "",
        'isKiosk': true,
        'isCorporate': false,
        'tagId': "",
        'processType': Operations.verify_item,
        'generateOTP': this.initialResponseData.otpEnable 
      }
      this.selfModePatronInfoWithoutCardService.patronVerification(body, (data) => {
        if (data && data.data) {
          //console.log("data", data.data);
          let patronData: SelfPatron = new SelfPatron();
        
          patronData = data.data; 
          this.reuseDataService.updateSelfPatronState(patronData);
          //successful patron login
          this.appState.patronLoggedIn = true;
          this.appStateService.update(this.appState);
          this.router.navigate([AppConstants.routes.self], { relativeTo: this.activatedRoute });
        } else {
          this.appState.patronLoggedIn = false;
          this.appStateService.update(this.appState);
          this.errorMsg = data.message;
          //console.log(this.errorMsg);
        }
      });
    }else{
      this.errorMsg = "Please Enter User Id"
    }
  }

/**
 * This method gets executed on the click on keyboard symbol
 */

  
/**
 * This method will gets executed when user will leave the page.
 */
  ngOnDestroy() {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
  }
  keyboardClick(evt){
    // evt.stopImmediatePropagation();
    // evt.stopPropagation()
    this.openKeyboard = !this.openKeyboard;
    this.buttonClicked = true;
    // const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    // if(invalidControl){
    //   invalidControl.focus();
    // }
  }

}
