<div *ngIf="!showPatronLogin">
    <div *ngIf="(userType == constants?.userType?.admin); then thenBlock else elseBlock">
    </div>
</div>
<!-- [ngClass]="{'admin-align-seven' : numberOfHexagoninksMart == 7,'' : adminOptions.length == 8}" -->
<ng-template #thenBlock>
    <div class="" [ngClass]="{'admin-align-seven' : numberOfHexagoninksMart == 7,'admin-wrapper' : adminOptions.length == 8}">
        <div class="adminPlacementDiv" *ngFor="let item of adminOptions;let i = index;">
            <div (click)="routeToPath(item?.route, null, item?.visibility)">
                <ng-container *ngIf='item.inActiveInKsMart'>
                    <app-hexagon [hexData]="item"></app-hexagon>
                </ng-container>
            </div>
        </div>  
    </div> 
</ng-template>

<ng-template #elseBlock>
    <div [ngClass]="{'user-align-four': numberOfHexagons==4, 'user-align-three': numberOfHexagons==3, 'user-align-two': numberOfHexagons==2}">
        <div class="userPlacementDiv" *ngFor="let item of userOptions;let i = index;">
            <div (click)="routeToPath(item?.route, item?.queryParam, item?.visibility)" *ngIf="item?.visibility">
                <ng-container *ngIf='item.inActiveInKsMart'>
                    <app-hexagon [hexData]="item"></app-hexagon>
                </ng-container>
            </div>             
            <!-- (click) = method(item.label) -->
        </div>
    </div>
</ng-template>

<div *ngIf="showPatronLogin">
    <app-barcode-login></app-barcode-login>
</div>