import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';

/**
 * @class HttpService
 * @author Saurabh bajpai
 */
@Injectable()
/**
 * This service is available as an injectable class, with multiple methods to perform HTTP requests, and also check wheater Show the loader on the screen or not
 */
export class HttpService {  
  /**
   * Used to show the unknown error messages
   */
  unkownErrorMsg: string = 'Unknown Error Occurred.'
  /**
   * @constructor function
   * @param platformId 
   * @param http Instance of HttpClient will be injected into the HttpService
   * @param loaderService LoaderService will be injected into the component
   * @param appStateService AppStateService will be injecte into the component
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private loaderService: LoaderService,
    private appStateService: AppStateService
  ) { }
    /**
     * This method used to send GET type request.
     * @param url 
     * @param requestParams 
     * @param callback 
     * @param error 
     * @param isLoader 
     */
  public get(url: string, requestParams: Map<string, string>, callback: (data) => void, error?: (error) => void, isLoader: boolean = true): Object {
    let params: HttpParams = new HttpParams();
    requestParams.forEach((value, key) => {
      params = params.set(key, value);
    });
    if (isLoader) {
      this.showLoader();
    }
    return this.http.get(url, { params: params }).subscribe(data => {
      this.hideLoader();
      if (isPlatformBrowser(this.platformId)) {
        if (data != undefined && data['token']) {
          this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.token, data['token']);
        }
      }
      callback(data);
    }, err => {
      this.errorHandler(err);
    });
  }
    /**
     * This method used to send POST type request.
     * @param url 
     * @param body 
     * @param requestParams 
     * @param callback 
     * @param error 
     * @param isLoader 
     */
    post(url: string, body: any, requestParams: Map<string, string>, callback: (data) => void, error?: (error) => void, isLoader: boolean = true): Object {
    let params: HttpParams = new HttpParams()
    requestParams.forEach((value, key) => {
      params = params.set(key, value)
    });
    if (isLoader) {
      this.showLoader();
    }
    return this.http.post(url, body, { params: params }).subscribe(data => {
      if (isPlatformBrowser(this.platformId)) {
        this.hideLoader();
        if (data != undefined && data['token']) {
          this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.token, data['token']);


        }
      }
      callback(data);
    }, err => {
      this.errorHandler(err);
    });
  }
  /**
   * @method tokenInvalidateHandler used to clear local storage
   */
  tokenInvalidateHandler() {
    //clear localStorage
    localStorage.clear();
    location.href = '';
  }
  /**
   * This @method errorHandler will hide the loader and varify the status and check if status is 401 then it call @method tokenInvalidateHandler
   * @param err 
   */
  errorHandler(err) {
    this.hideLoader();
    console.log(err);
    if (err.status == 401) {
      this.tokenInvalidateHandler();
    }
  }
  /**
   * This method used to show Loader
   */
  private showLoader() {
    this.loaderService.show();
  }
  /**
   * This method used to hide loader
   */
  private hideLoader() {
    this.loaderService.hide();
  }

}
