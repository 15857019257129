import { Component, OnInit } from '@angular/core';
import { HexagonModel } from '../../model/hexagonModel';
import { AppConstants } from '../../constants/app.constants';
import { Router, RouterEvent, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../service/appState.service';
import { AppState } from '../../service/app-state';
import { InitialResponseDTO } from '../../model/initial-response.dto';
import { LoginService } from '../../service/login.service';
import { LoginResponseDTO } from '../../model/login.response';
import { RFIDLayerService } from '../../service/rfidLayer.service';
import { FetchBookResponseDTO } from '../../model/fetchBook.dto';
import { ItemInfoRFIDService } from '../../service/itemInfoRFID.service';
import { ManualCirculationRequestDto } from '../../model/manualCirculationRequestDto.model';
import { SelfPatronActionsService } from '../../service/self-patron-actions.service';
import { TransactionService } from '../../service/transaction.service';
import { LsDialogService } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { BackDropPopUpComponent } from '../back-drop-pop-up/back-drop-pop-up.component';


/**
  * @class DashboardComponent 
  * @author Kartik Goel
  */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
/**
 * This component displays the self and Admin dashboard page with hexagons
 */
export class DashboardComponent implements OnInit {

  /**
  *This array holds the hexagon models of admin dashboard 
  */
  adminOptions: Array<HexagonModel> = [];

  /**
  *This array holds the hexagon models of user dashboard 
  */
  userOptions: Array<HexagonModel> = []; 
  
  /**
  *Local instance of global appstate
  */
  appState: AppState = new AppState();

  /**
  *Used for decide the number of hexagons to show in user dashboard
  */
  numberOfHexagons: number;

  /**
  *Instance of class Appconstants containing all global constants locally
  */
  constants = AppConstants;

  /**
  *stores the type of user whether self or Admin
  */ 
  userType = AppConstants.userType.self;

  /**
  *Flag used to show or hide barcode-login component
  */
  showPatronLogin: boolean;

  /**
  *Local variable to store login response
  */
  loginResp: LoginResponseDTO;
  rfidErrorCode: string;
  bookInfo:Array<FetchBookResponseDTO> = [];
  isKsMart: any;
  numberOfHexagoninksMart: number;
  setInitialBackDrop: NodeJS.Timeout;
 

  // public doUnload(): void{
  //   localStorage.clear();
  //   location.href = '';
  // }
  

  /**
   * @constructor function
   * @param appstateService AppstateService service will be injected into the component 
   * @param router Router service that will get injected into this component  
   * @param activatedRoute ActivatedRoute service will be injected into the component   
   * @param loginService LoginService service will be injected into the component 
   */
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private rfidLayerService: RFIDLayerService,
    private itemInfoRFIDService: ItemInfoRFIDService,
    private selfPatronActionService: SelfPatronActionsService,
    private transactionService : TransactionService,
  ) {
    this.numberOfHexagons = 0;
    this.loginResp = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.loginResponse, true);
    this.numberOfHexagoninksMart = 0;
  }

  /**
   * This function will gets executed on load of this component.
   */
  ngOnInit() {
    this.isKsMart = JSON.parse(localStorage.getItem('inStng'));

    this.appStateService.appStateModel.subscribe((appState: AppState) => {
      this.appState = appState;
      this.userType = this.appState.userType;
      
      if (this.userType == AppConstants.userType.self) {
        let initialResp: InitialResponseDTO = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings);
        if (initialResp.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.barcode) {
          // this.navigateToSelf();
          this.showPatronLogin = true;
        } else {
          this.showPatronLogin = false;
          // this.navigateToDashboard();
        }
      }
    });

    this.initializeAdminOptions();
    this.initializeUserOptions();

    this.numberOfHexagons = this.userOptions.length;
    if(this.isKsMart.ksmart){
      this.adminOptions.splice(1,1);
      this.numberOfHexagoninksMart = this.adminOptions.length;
    }
  }

 


  /**
  *Pushes Admin dashboard hexagons to array to display and enable or disable according to login service response
  */
  initializeAdminOptions() {
    this.adminOptions = [
      { 
        label: AppConstants.heading.tagging,
        imgUrl: 'assets/svgs/Tagging.svg',
        route: AppConstants.routes.tagging,
        visibility: (this.loginResp.taggingButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/Tagging-white.svg',
        disableUrl: 'assets/svgs/svgDisable/Tagging-disable.svg',
        inActiveInKsMart: true
      },
      {
        label: AppConstants.heading.databaseQuery,
        imgUrl: 'assets/svgs/database_query.svg',
        route: AppConstants.routes.databaseQuery,
        visibility: (this.loginResp.bookDBQueryButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/database_query-white.svg',
        disableUrl: 'assets/svgs/svgDisable/database_query-disable.svg',
        inActiveInKsMart: !this.isKsMart.ksmart
      },
      {
        label: AppConstants.heading.checkOut,
        imgUrl:  'assets/svgs/Check-in.svg',
        route: AppConstants.routes.checkOut,
        visibility: (this.loginResp.checkOutButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/Check-in-white.svg' ,
        disableUrl: 'assets/svgs/svgDisable/Check-out-disable.svg',
        inActiveInKsMart: true
      },
      {
        label: AppConstants.heading.checkIn,
        imgUrl: 'assets/svgs/Check-out.svg',
        route: AppConstants.routes.checkIn,
        visibility: (this.loginResp.checkInButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/Check-out-white.svg',
        disableUrl: 'assets/svgs/svgDisable/Check-in-disable.svg',
        inActiveInKsMart: true
      },
      {
        label: AppConstants.heading.renew,
        imgUrl: 'assets/svgs/Renew.svg',
        route: AppConstants.routes.renew,
        visibility: (this.loginResp.renewButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/Renew-white.svg',
        disableUrl: 'assets/svgs/svgDisable/Renew-disable.svg',
        inActiveInKsMart: true
      },
      {
        label: AppConstants.heading.patron,
        imgUrl: 'assets/svgs/patron.svg',
        route: AppConstants.routes.patron,
        visibility: true,
        hoverUrl: 'assets/svgs/svgWhite/patron-white.svg',
        disableUrl: 'assets/svgs/svgDisable/patron-disable.svg',
        inActiveInKsMart:true

      },
      {
        label: AppConstants.heading.monitoringRfid,
        imgUrl: 'assets/svgs/monitoring_rfid_card.svg',
        route: AppConstants.routes.monitoringRfid,
        visibility: (this.loginResp.monitorButton == 'Y'),
        hoverUrl: 'assets/svgs/svgWhite/monitoring_rfid_card-white.svg',
        disableUrl: 'assets/svgs/svgDisable/monitoring_rfid_card-disable.svg',
        inActiveInKsMart: true
      },
      {
        label: AppConstants.heading.exit,
        imgUrl: 'assets/svgs/exit.svg',
        route: '',
        visibility: true,
        hoverUrl: 'assets/svgs/svgWhite/exit-white.svg',
        disableUrl: 'assets/svgs/svgDisable/exit-disable.svg',
        inActiveInKsMart: true
      }
    ]
  }

  /**
  *Pushes user dashboard hexagons to array to display according to settings
  */
  initializeUserOptions() {
    let biometric = {
      label: AppConstants.heading.biometric,
      imgUrl: 'assets/svgs/fingerprint_register.svg',
      route: AppConstants.routes.self,
      visibility: true,
      hoverUrl: 'assets/svgs/svgWhite/fingerprint_register-white.svg',
      queryParam: 'biometric',
      inActiveInKsMart:true
    };
    let smartCard = {
      label: AppConstants.heading.smartcard,
      imgUrl: 'assets/svgs/patron.svg',
      route: AppConstants.routes.self,
      visibility: true,
      hoverUrl: 'assets/svgs/svgWhite/patron-white.svg',
      queryParam: undefined,
      inActiveInKsMart:true
    };
    let checkIn = {
      label: AppConstants.heading.checkIn,
      imgUrl: 'assets/svgs/Check-in.svg',
      route:  'self/'+AppConstants.routes.selfCheckin,
      visibility: true,
      hoverUrl: 'assets/svgs/svgWhite/Check-in-white.svg',
      queryParam: undefined,
      inActiveInKsMart:true
    };
    let renew = {
      label: AppConstants.heading.renew,
      imgUrl: 'assets/svgs/Renew.svg',
      route: 'self/'+ AppConstants.routes.selfRenew,
      visibility: true,
      hoverUrl: 'assets/svgs/svgWhite/Renew-white.svg',
      queryParam: undefined,
      inActiveInKsMart:true
    };

    let initialResponse: InitialResponseDTO = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings, true);
    if (initialResponse.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.biometric) {
      this.userOptions.push(biometric);
    }
    if (initialResponse.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.smartCard) {
      this.userOptions.push(smartCard);
    }
    if (initialResponse.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.biometric_smartCard) {
      this.userOptions.push(biometric);
      this.userOptions.push(smartCard);
    }
    if (initialResponse.checkinWithoutMember) {
      this.userOptions.push(checkIn);
    }
    if (initialResponse.renewWithoutMember) {
      this.userOptions.push(renew);
    }
    if(this.userOptions.length===1 && initialResponse.selfMemberIdentificationMode>0 ){
      this.routeToPath(this.userOptions[0].route,this.userOptions[0].queryParam, true, false)
    }
  }
  
  /**
  *Routes to corresponding path on clicking a hexagon and logouts user if url is login
  */
  routeToPath(route, queryParam?, visibility=true, multiHex=true) {
    // console.log(route);
    
  if(visibility){
    if (route == '' || route == AppConstants.routes.login) {
      this.loginService.adminExitActions();
    }
    if(queryParam && multiHex){
      this.router.navigate([route], {queryParams: {q: queryParam}, relativeTo: this.activatedRoute});
    }else if(queryParam || !multiHex){
      this.router.navigate([route], {queryParams: {q: queryParam, m: false}, relativeTo: this.activatedRoute});

    } else {
      this.router.navigate([route], { relativeTo: this.activatedRoute });
    }
    this.transactionService.updateRoute(route);

  }
    
  }

  // method(item: any, route,  queryParam?, visibility=true, multiHex=true ){
  //   this.routeToPath(item.route, item.queryParam, item.visibility);
  //   if(item.label == AppConstants.heading.checkIn){
  //     console.log("service called");
  //   }
  // }


  // method(){
  //     this.rfidLayerService.rfidTagDetails((resp)=>{
  //       console.log("rfid response", resp);
  //       if(resp && resp.error){
  //         //error occured
  //       }else if(resp && resp.startswith('#')) {
  //         this.rfidErrorCode = resp;
  //       }else{
  //         this.rfidErrorCode = undefined;
  //         let rfidResp = resp;
  //         this.itemInfoRFIDService.getItemInfoRFID(rfidResp,(resp) => {
  //           console.log("resp",resp);
  //           if(resp && resp.data){
  //             this.bookInfo = resp.data;
  //             let body = []
  //             this.bookInfo.forEach(ele=>{
  //               let x =new ManualCirculationRequestDto();
  //               x.itemId = ele.item_identifier;
  //               x.itemType = AppConstants.ITEM_REV_TYPE.get(ele.media_type);
  //               x.tagId = ele.tagId;
  //               body.push(x);
  //             });
  //             this.selfPatronActionService.manualCirculationRequestDto = body;
  //             this.router.navigate(['/','dashboard','self','self-checkin']);
  //           }else{
  //             //error handling
  //           }
  //         })
  //       }
  //     })
    
  // }

}
