import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { OverlayModule } from '@angular/cdk/overlay';
// import Keyboard from 'simple-keyboard';


import { OkPopUpComponent } from '../../common/components/ok-pop-up/ok-pop-up.component';
import { BiometricPopUpComponent } from '../../modules/features/biometric/biometric-pop-up/biometric-pop-up.component';
import { RenewPopUpComponent } from '../../modules/features/self/my-account/renew-pop-up/renew-pop-up.component';
import { ShowRenewDataComponent } from '../../modules/features/self/my-account/show-renew-data/show-renew-data.component';
import { HexagonComponent } from '../../common/components/hexagon/hexagon.component';
import { RfidErrorHandlerComponent } from "src/app/common/components/rfid-error-handler/rfid-error-handler.component";
import { MessageComponent } from '../../common/components/message/message.component';
import { VirtualkeyboardComponent } from '../../common/components/virtualkeyboard/virtualkeyboard.component';
import { OtpkeyboardComponent } from '../../common/components/otpkeyboard/otpkeyboard.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { SafeHtmlPipe } from 'src/app/common/pipe/safe-html.pipe';
import { SafeUrlPipe } from 'src/app/common/pipe/safe-url.pipe';
import { PageNotFoundComponent } from 'src/app/common/components/page-not-found/page-not-found.component';

//LSNG Components
import { LsTextFieldModule } from 'src/LSNG/components/ls-textfield/ls-textfield.module';
import { LsRadioModule } from 'src/LSNG/components/ls-radio/ls-radio.module';
import { LsButtonModule } from 'src/LSNG/components/ls-button/ls-button.module';
import { LsToggleModule } from 'src/LSNG/components/ls-toggle/ls-toggle.module';
import { LsCheckboxModule } from 'src/LSNG/components/ls-checkbox/ls-checkbox.module';


import { DefaultValuePipe } from 'src/app/common/pipe/default-value.pipe';
import { LsComboModule1 } from 'src/LSNG/components/ls-combo-1/ls-combo.module';
import { LsDialogModule } from 'src/LSNG/components/ls-dialog/ls-dialog.module';
import { LsLazyLoaderDirective } from 'src/LSNG/directives/ls-lazy-loader/ls-lazy-loader.directive';
import { TimeSlotAlterPipe } from 'src/LSNG/commons/time-slot-alter.pipe';
import { OrdinalPipe } from 'src/LSNG/commons/ordinal.pipe';
import { LsDatePickerModule } from "src/LSNG/components/ls-date-picker/ls-date-picker.module";
import { LsPaginationModule } from 'src/LSNG/components/ls-pagination/ls-pagination.module';

//services
import { HttpInterceptorService } from 'src/app/common/service/http-interceptor.service';
import { AuthGuardService } from 'src/app/common/service/auth-guard.service';
import { SelfModeWithoutCardGuardService } from 'src/app/common/service/self-mode-withoutCard-guard.service';
import { SelfModeWithCardGuardService } from 'src/app/common/service/self-mode-withCard-guard.service';
import { RfidItemPipe } from 'src/app/common/pipe/rfid-item.pipe';
import { LsFileUploadModule } from 'src/LSNG/components/ls-file-upload/ls-file-upload.module';
import { LsFileUploadService } from 'src/LSNG/components/ls-file-upload/ls-file-upload.service';
import { LoaderComponent } from 'src/app/common/components/loader/loader.component';
import { RouterModule } from '@angular/router';
import { LsDialogService } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { PopUpComponent } from 'src/app/common/components/pop-up/pop-up.component';
import { BarcodeLoginComponent } from '../features/self/without-card/barcode-login/barcode-login.component';
import { SelfModeGuardService } from 'src/app/common/service/self-mode-guard.service';
import { UtilsService } from 'src/app/common/service/utils.service'; 


@NgModule({
    declarations: [
        OkPopUpComponent,
        BiometricPopUpComponent,
        RenewPopUpComponent,
        ShowRenewDataComponent,
        HexagonComponent,
        MessageComponent,
        VirtualkeyboardComponent,
        OtpkeyboardComponent,
        FooterComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        PageNotFoundComponent,
        RfidErrorHandlerComponent,
        DefaultValuePipe,
        LsLazyLoaderDirective,
        TimeSlotAlterPipe,
        OrdinalPipe,
        RfidItemPipe,
        LoaderComponent,
        PopUpComponent,
        BarcodeLoginComponent
    ],
    imports: [
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        LsTextFieldModule,
        LsRadioModule,
        LsButtonModule,
        OverlayModule,
        LsToggleModule,
        LsCheckboxModule,
        LsComboModule1,
        LsFileUploadModule,
        LsDatePickerModule,
        LsPaginationModule,
        LsDialogModule,
        // Keyboard
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        AuthGuardService,
        SelfModeWithoutCardGuardService,
        SelfModeWithCardGuardService,
        SelfModeGuardService,
        LsFileUploadService,
        LsDialogService,
        UtilsService
    ],
    exports: [
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        LsTextFieldModule,
        LsRadioModule,
        LsButtonModule,
        LsCheckboxModule,
        LsDialogModule,
        OverlayModule,
        OkPopUpComponent,
        BiometricPopUpComponent,
        RenewPopUpComponent,
        ShowRenewDataComponent,
        HexagonComponent,
        MessageComponent,
        VirtualkeyboardComponent,
        OtpkeyboardComponent,
        FooterComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        PageNotFoundComponent,
        LsToggleModule,
        RfidErrorHandlerComponent,
        DefaultValuePipe,
        LsComboModule1,
        LsLazyLoaderDirective,
        TimeSlotAlterPipe,
        OrdinalPipe,
        RfidItemPipe,
        LsFileUploadModule,
        LoaderComponent,
        LsDatePickerModule,
        LsPaginationModule,
        PopUpComponent,
        BarcodeLoginComponent,
    ]
})
export class SharedModule { }
