<!-- <div class="c1" *ngFor="let option of menuOption" (click)="routeToOption(option.path)" fxLayout = 'row'>
    <div class="options">{{option.item}}</div>
    <div class="icon"><img [src]=option.icon></div> 
</div> -->
<div class="menuClass" >
    <a *ngFor="let option of menuOption" routerLink={{option.path}} fxLayout='row' (click)="closePopup()">
        <ng-container *ngIf='option.showItem'>
                <div class="options">{{option.item}}</div>
                <div class="icon"><img [src]=option.icon></div>
        </ng-container>
      
    </a>
</div>
