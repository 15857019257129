import { Component, OnInit, Output } from '@angular/core';
import { LsMdDialog} from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FingerprintLayerService } from 'src/app/common/service/fingerprint-layer.service';
import { FingerprintDTO } from 'src/app/common/model/fingerPrintDTO';
import { BiometricService } from '../biometric.service';
import { PatronStatusResponse } from 'src/app/common/model/patronStatusResponseModel';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common/constants/app.constants';

/**
 * @class BiometricPopUpComponent
 * @author Saurabh bajpai
 */
@Component({
  selector: 'app-biometric-pop-up',
  templateUrl: './biometric-pop-up.component.html',
  styleUrls: ['./biometric-pop-up.component.scss'] 
})
/**
 * This component shows us a pop up which is used for Biometric registration , verification etc.
 */ 
export class BiometricPopUpComponent implements OnInit {
  /**
   * Instance of class LsMdDialog
   */
  dialog: LsMdDialog<BiometricPopUpComponent> | any
  /**
   * Used to share Data
   */
dataList
  /**
   * Used to show error message
   */
  errorMsg: string;
  /**
   * Array used to store fingerprint response
   */
fingerRespArr: Array<FingerprintDTO> = [];
/**
 * Used to display Heading message
 */
displayHeading: string;
/**
 * Used to show message
 */
message: string;
/**
 * Initially No of times finger scanned is set to 0
 */
start: number = 0;
/**
 * Finger must be scan atleast 3 times
 */
end: number = 3;
  /**
   * No of times fingerprint scan
   */
  fpCount: number;
  /**
   * remaining attempts of finger print scan while registration
   */
  remainingAttempts: number;
  /**
   * Decieds after 3 times of finger print scanning User go for more scan or not
   */
  showMoreScan: boolean = false;
  /**
   * To store patron ID
   */
  patId: string;
  /**
   * To show User Status
   */
  userStatus: string; 
  /**
   * Value will change once user scan their finger more than 3 times.Used to navigate to the save information action
   */
  cancelActionChanged: boolean = false; 
  /**
   * To show the message "would you like to save".
   */
  savaInformation: boolean = false;
  /**
   * Biometric user registered successfully or not
   */
  biometricSuccessfull: boolean = false;
  /**
   * To show biometric response message
   */
  biometricResponseMsg: string;
  /**
   * Type of message either 'success' or 'warn'
   */
  type;
  /**
   * To show the message User not registered successfully 
   */
  userNotRegMsg: string;
  /**
   * To check the response of either user registerd or not
   */
  userNotRegResp: boolean;
  /**
   * To check either rfid layer working or not
   */
  rfidNotworking: boolean = false;
  /**
   * To check either flow goes to verification process
   */
  verificationProcess: boolean = false; 
  /**
   * To store patron Status Response
   */
  patronInfo: PatronStatusResponse = new PatronStatusResponse();
  /**
   * navigate to verification process
   */
  navigateToverifyPage: boolean = true;
  constants: AppConstants;
  /**
   * @constructor function
   * @param fingerprintLayerService FingerprintLayerService will be injected into the component 
   * @param biometricService BiometricService will be injected into the component
   * @param router Router service will be injected into the component
   */
  constructor(
    private fingerprintLayerService: FingerprintLayerService,
    private biometricService: BiometricService,
    private router: Router
  ) {
   }  
  /**
   * This method will execute on the load of this component.
   */
  ngOnInit() { 
    //console.log("pop up resoponse")
    // this.cancelButton();
    this.displayHeading = "Scanned count {start} of {end}"
    this.message = "Put your finger on the scanner";
    // console.log();
    this.fpCount= this.dataList.fpCount;
    
    //console.log(this.dataList.patronId);
    this.patId= this.dataList.patronId;
    this.verificationProcess= false || this.dataList.verification
  }
  // num=0V
  /**
   * This method will execute on the click on CONFIRM button while fingerprint registration
   */
  confirmButton(){
    this.fingerprintLayerService.fingerprintLayerResponse((fingerResp) => {
      if(fingerResp || fingerResp == null){
        if(fingerResp == null){
          this.message = "No Fingerprint Found!";
          this.rfidNotworking = true;

          setTimeout(() => {
            this.message = "Put your finger on the scanner";
            this.rfidNotworking = false;
          },AppConstants.global.ERROR_MSG_TIMEOUT);

          console.log(this.errorMsg)
        }else if(fingerResp.error){
          this.errorMsg = "Fingerprint Layer not connected";
          this.type = 'warn';
        }
        else{
          // if(this.start < this.end){ 
          //   this.message = "Put your finger on the scanner"
          //   this.rfidNotworking = false
          //   // this.fingerRespArr = fingerResp; 
          //   this.fingerRespArr.push(fingerResp);
          //   console.log("Fingerprint Response", this.fingerRespArr);
          //   this.start++; 
          // }else{
          //   if(this.start == this.fpCount){
          //     // registration successfull -->> verification
          //     console.log("user registered");
          //     this.registerUser(); 

          //   }else if(this.start < this.fpCount){
          //     this.message = "Would you like to scan more";
          //     this.showMoreScan = true;
          //     this.cancelActionChanged = true;
          //     this.remainingAttempts = this.fpCount - this.start;
          //     this.end = this.end + this.remainingAttempts;
          //   }
          // }


          this.fingerRespArr.push(fingerResp);
          if(this.fingerRespArr.length < this.end){ 
            this.message = "Put your finger on the scanner"
            this.rfidNotworking = false
            // this.fingerRespArr = fingerResp; 
            //console.log("Fingerprint Response", this.fingerRespArr);
            this.start++; 
          }else{
            if(this.fingerRespArr.length === this.fpCount){
              // registration successfull -->> verification
              //console.log("user registered");
              this.registerUser(); 

            }else if(this.fingerRespArr.length < this.fpCount){
              this.start++;
              this.message = "Would you like to scan more";
              this.showMoreScan = true;
              this.cancelActionChanged = true;
              this.remainingAttempts = this.fpCount - this.start;
              this.end = this.end + this.remainingAttempts;
            }
          }
        }
      }else{
        this.message= "Fingerprint layer not connected";
        this.rfidNotworking = true
        console.log(this.errorMsg);
        //console.log(this.dataList.fpCount);
      }
    } , err =>{
      this.message = "Fingerprint layer not connected";
      this.rfidNotworking = true;
    }
    
    )
  }
  /**
   * This method will execute on CANCEL button clicked
   */
  cancelButton(){
    //console.log("User Not Registered");
    this.userNotRegMsg = "User Not Registered";
    this.userNotRegResp = true;
    let data = {
      userNotregMsg: this.userNotRegMsg,
      userNotregResp: this.userNotRegResp
    }
    this.dialog.close(data);
  }
  /**
   * This method will execute on CANCEL butto clicked(afte 3 fingerprint registered)
   */
  cancelAfterScanMore(){
    this.message = "Would you like to save";
    this.savaInformation = true;
  }
  /**
   * To scan more fingerprint
   */
  scanMore(){ 
    this.message = "Put your finger on the scanner";
    this.showMoreScan= false;
    // this.confirmButton(); 
  }
  /**
   * execute on the click of cancel button before 3 fingerprint scan
   */
  ShowUserNotRegistered(){
    //console.log("user not registered");
    this.userNotRegMsg = "User Not Registered";
    this.userNotRegResp = true;
    let data = {
      userNotregMsg: this.userNotRegMsg,
      userNotregResp: this.userNotRegResp
    }
    this.dialog.close(data);
  }
  /**
   * This method will execute the uer will registered successfully
   */
  userRegistered(){
    this.registerUser();
    //console.log("would you like to verify");
  } 
  /**
   * This method used to show success message  and also share the data from popup component to registration component
   */
  showSuccessMessage(){
   // console.log("User registered successfully");
    let data ={
     success:  this.biometricSuccessfull,
     respmsg:  this.biometricResponseMsg
      }
    this.dialog.close(data);
  }
  /**
   * This method execute on click on verify button
   */
  navigateToVerification(){
    this.dialog.close();
    this.router.navigate(['/dashboard/biometric/verify-fingerprint']);
  }
/**
 * This method will exexute on the click on No button when user got the message "would you like to verify"
 */
  registerUser(){
    this.message= "would you like to verify";
    this.biometricService.biometricRegistration(this.fingerRespArr , this.patId , (resp) =>{
      //console.log("resp", resp);
      if(resp){ 
        this.userStatus = "Registered user";
        this.biometricSuccessfull = true;
        this.biometricResponseMsg= resp.message;
        
      }
    })
  }
  /**
   * This method will execute on the click on CANCEL button
   */
  cancelVerification(){
    //console.log("cencel the verification process");
    this.dialog.close();
  }
  /**
   * This method will execute when user click on verify button or Yes button or click on verify Hexagon
   * This method used to verification process.
   */
  verifiedUser(){ 
    this.fingerprintLayerService.fingerprintLayerResponse((fingerResp)=>{
      if(fingerResp || fingerResp == null){
        if(fingerResp == null){
          this.message = "No Fingerprint Found!" 
          this.rfidNotworking = true;
          
          setTimeout(() => {
            this.message = "Put your finger on the scanner";
            this.rfidNotworking = false;
          }, AppConstants.global.ERROR_MSG_TIMEOUT);

          //console.log(this.errorMsg);
        }else if(fingerResp.error){
          this.errorMsg = "Finger Print Layer  not connected";
          this.type= 'warn';
        }else{
          this.fingerRespArr = fingerResp
          this.biometricService.biometricVerification(this.fingerRespArr , (verifiedResp)=>{
            //console.log(verifiedResp);
            if(verifiedResp){
              if(verifiedResp.message){
                //console.log("No match Found");
                this.errorMsg= verifiedResp.message;
                AppConstants.verificatinDetails.verificationData = this.errorMsg;
                this.biometricService.verificationDataObs.next(true);
                let data ={
                  success: this.navigateToverifyPage, 
                }
                this.dialog.close(data);
                // this.router.navigate(['/dashboard/biometric/verify-fingerprint']);
              }else{
                //console.log(verifiedResp.data); 
                this.patronInfo = verifiedResp.data;
                AppConstants.verificatinDetails.verificationData = this.patronInfo;
                this.biometricService.verificationDataObs.next(true);
                let data = {
                  success: this.navigateToverifyPage,
                }
                this.dialog.close(data); 
                
                // this.router.navigateByUrl('/dashboard/biometric/verify-fingerprint', {state : {data: this.patronInfo }});
              }
            }
          })
        }
      }
    },err => {
      this.message = "Biometric Server not connected";
      this.type = "warn";
      this.rfidNotworking = true;
      //console.log(this.message);
    })
  }
  ngOnDestroy(){
    console.log("running...");
  }
}
