import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReplyDTO } from '../model/reply.dto';
import { InitialResponseDTO } from '../model/initial-response.dto';
import { AppConstants } from '../constants/app.constants';
import { RFIDLayerService } from './rfidLayer.service';
import { AppStateService } from './appState.service';
import { AppState } from './app-state';
import { async } from 'q';

/**
 * @class StartupService
 * @author Saurabh bajpai
 */
@Injectable()
/**
 * Startup service execute on the load of the Lsmart
 */
export class StartupService {
  /**
   * Instance of appState
   */
  appState: AppState = new AppState();
  /**
   * to store intial service data
   */

  /**
   * 
   * @param _http HttpClient service will be injected into the component
   * @param rfidLayerService RFIDLayer Service will be injected into the component
   * @param appStateService appStateService will be injected into the component
   */
  constructor(
    private _http: HttpClient,
    private rfidLayerService: RFIDLayerService,
    private appStateService: AppStateService
  ) {
    this.appStateService.appStateModel.subscribe((appState: AppState) => {
      this.appState = appState;
    });


  }

  // private eventCallback = new Subject<string>();
  // eventCallback$ = this.eventCallback.asObservable();
  /**
   * @method bootstrapAppSettings @returns Promise 
   * This method used to bootstrapAppSettings
   * On the load of this service first of all its update environment variables such that service url and rfid url
   * After this send initial request to get initial data
   * Now its set initial data in global constant file
   * Now its check rfid layer status
   */
  bootstrapAppSettings(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      //read setting file from assets (eg; app.dev.json)
      this._http.get('assets/' + environment.APP_CONFIG).subscribe((data: any) => {
        //update environment variables
        environment.service_url = data.service_url;
        environment.rfid_url = data.rfid_url;
        //send initial request
        this.initialRequest(async (resp: ReplyDTO) => {
          if (resp.data) {
            this.appState.isInitialRespErr = undefined;
            this.appStateService.update(this.appState);
            let data: InitialResponseDTO = resp.data;
            //set initial data in global constants
            this.setInitialData(data);
            //check rfid layer status 
            //asyncronous behaviour in startup service 05-12-228
              let connectRfid: any = await this._http.post(environment.rfid_url, AppConstants.requestCodes.CONNECT_RFID,{ headers: {"no-auth":"true"}, responseType: 'text' }).toPromise();
              // this.rfidLayerService.rfidLayerStatus((resp: string) => {              
              //   resolve(true);
              //todo
              // if (resp) {
              //   // this.eventCallback.next(resp);
              // } else {
              //   // reject("rfid layer not working");
              //   // this.appState.isInitialRespErr = 'rfid layer not working';
              //   this.appStateService.update(this.appState);
              // }
              // });
              if (data.emSecurity) {
                let connectEmSecurity: any = await this._http.post(environment.rfid_url, AppConstants.requestCodes.CONNECT_EM,{ headers: {"no-auth":"true"}, responseType: 'text' }).toPromise();
               
                // this.rfidLayerService.rfidEmSecurityLayerStatus(res => {
                //   resolve(true);
                // });
              }
              let checkSecondReader: any = await this._http.post(environment.rfid_url, AppConstants.requestCodes.CHECK_READER,{ headers: {"no-auth":"true"}, responseType: 'text' }).toPromise();
                localStorage.setItem('nextRfidReader',checkSecondReader)
              
              // this.rfidLayerService.rfidLayerCheckReader(resp =>{
              //   console.log(resp);
              //   resolve(true);
              // })
              resolve(true);
          } else {
            reject("initial response not received.");
            if (resp.flag == AppConstants.replyErrType.err) {
              this.appState.isInitialRespErr = resp.message;
              this.appStateService.update(this.appState);
            }
          }
        }, err => {
          reject(err)
        });
      }, (err) => {
        console.log('service is not running!!!')
        reject(err);
      });
    });
  }
  /**
   * This method used to fire initial request and get the initial data
   * In case initial request is not running , it will show some error messages,
   * @param callback function
   */
  initialRequest(callback: (data) => void, error?) {
    let url = environment.service_url + '/initial';
    this._http.get(url).subscribe((resp: ReplyDTO) => {
      callback(resp);
      // console.log(resp.data,'print',resp);

    }, (err) => {
      console.log("backend is not working!!!")
      if (error) {
        error(err);
      }
      this.appState.isInitialRespErr = `Sorry! Somthing Unexpected Happens, Contact System Admin,
      ${err.statusText}
      ` ;
      this.appStateService.update(this.appState);
    });
  }
  /**
   * This method used to set Initial Data in Initial response DTO
   * @param data initial Data
   */
  setInitialData(data: InitialResponseDTO) {
    environment.rfid_url = data.rfidUrl;
    environment.fingerprint_url = data.fingerprintLayerUrl;
    //data.selfMemberIdentificationMode = AppConstants.selfIdentificationModes.barcode;//remove this line, it is only for testing
    this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.initialSettings, data, true);
    this.setSelfMode(data.selfMemberIdentificationMode);
    AppConstants.global.initialRequestData = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings);
    let userType = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userType);
    //get login response from localStorage, if present
    let userId = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userId);
    let loginResp = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.loginResponse);
    // let otherRfidReader = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.NextRfidReader);
    if (loginResp) {
      this.appState.displayUserId = userId;
      this.appState.isLoggedIn = true;
      this.appState.loginResponse = loginResp;
      this.appState.userType = userType;
      // this.appState.nextRfidReader = otherRfidReader;
      // console.log(this.appState);

      this.appStateService.update(this.appState);
    }
  }
  /**
   * This method helps us to identify weather we follow the manual mode or automatic mode while login with Self.
   * @param selfMemberIdentificationMode 
   */
  setSelfMode(selfMemberIdentificationMode: number) {
    let isWithCard: boolean;
    if (selfMemberIdentificationMode == AppConstants.selfIdentificationModes.barcode) {
      isWithCard = false;
    } else {
      isWithCard = true;
    }
    // isWithCard = false;
    this.appState.selfModeWithCard = isWithCard;
    this.appStateService.update(this.appState);
  }
}