/**
 * To store initial response dto
 */
export class InitialResponseDTO {
    bgImagePath: string;
    libraryName: string;
    licenseName: string;
    rfidUrl: string;
    selfMemberIdentificationMode: number;
    fingerprintLayerUrl: string;
    operationMode: number;
    checkinWithoutMember: boolean;
    renewWithoutMember: boolean;
    displayMemberMobile: boolean;
    displayMemberEmail: boolean;
    adminBiometricEnable: boolean;
    renewUsingPatronId: boolean; //admin
    selfSelectionEnable: boolean;
    automaticPrint: boolean;
    patronCardVerification: boolean;
    renewWithoutBook: boolean;
    automaticExit: boolean;
    homeScreenTime: number;
    transactionScreenTime: number;
    accountScreenTime: number;
    patronPasswordEnable: boolean;
    otpEnable: boolean;
    hideAllInfo: boolean;
    hideExpiryDate: boolean;
    hideEntitlements: boolean;
    hideCategory: boolean;
    kioskPatronInstruction: string;
    kioskCirculationInstruction: string;
    database: number;
    strongPassword: boolean;
    selfAttempts:number;
    hideCheckOut:boolean;
    hideCheckIn:boolean;
    hideRenew: boolean;
    memberIdentificationMode: number;
    emSecurity:boolean;
}