export enum Operations {
        check_out = 0,
        check_in = 1,
        renew = 2,
        fine_payment = 3,
        retag = 4,
        untag = 5,
        tagging = 6,
        verify_item = 7,
        tagging_enquiry = 8
}
export const OperationsEnumMap = new Map().set(0,'CHECK_OUT').set(1,'CHECK_IN').set(2,'RENEW').set(3,'FINE_PAYMENT').set(4,'RETAG').set(5,'UNTAG').set(6,'TAGGING').set(7,'VERIFY_ITEM').set(8,'VERIFY_ITEM')

