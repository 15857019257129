import { Component, OnInit, Input } from '@angular/core';
/**
 * @class MessageComponent
 */
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
/**
 * To show message status type
 */
export class MessageComponent implements OnInit {
  /**
   * To store message text
   */
  @Input() messageText: string;
  /**
   * Decides status of message 
   */
  @Input() messageType: 'info' | 'success' | 'warn';
/**
 * @constructor function
 */
  constructor() { }
/**
 * executes on component load
 */
  ngOnInit() {    
  }

}
