import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { AppComponent } from './app.component';
import { StartupService } from './common/service/startup.service';
import { HomePageComponent } from './components/home-page/home-page.component';
import { LoginComponent } from './components/login/login.component';
import { PopUpTriggerDirective } from './common/components/pop-up/pop-up-trigger.directive';
import { MenuComponent } from './common/components/menu/menu.component';
import { AppStateService } from './common/service/appState.service';
import { DashboardComponent } from './common/components/dashboard/dashboard.component';
import { HttpService } from './common/service/http.service';
import { LoaderService } from './common/service/loader.service';
import { RouterModule } from '@angular/router';
import { FingerprintLayerService } from './common/service/fingerprint-layer.service';
import { OverDueDocumentDialogComponent } from './modules/features/over-due-document-dialog/over-due-document-dialog.component';
import { BackDropPopUpComponent } from './common/components/back-drop-pop-up/back-drop-pop-up.component';


export function startupServiceFactory(_startupService: StartupService) {
  return () => _startupService.bootstrapAppSettings().catch((err) => { 
    //console.log("err", err);
  });
} 

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomePageComponent,
        PopUpTriggerDirective,
        MenuComponent,
        DashboardComponent,
        OverDueDocumentDialogComponent,
        BackDropPopUpComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        RouterModule,
    ],
    providers: [
        StartupService,
        AppStateService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        HttpService,
        LoaderService,
        FingerprintLayerService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
