import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { send } from 'q';
import { ItemInfoRFIDService } from 'src/app/common/service/itemInfoRFID.service';

@Component({
  selector: 'app-over-due-document-dialog',
  templateUrl: './over-due-document-dialog.component.html',
  styleUrls: ['./over-due-document-dialog.component.scss']
})
export class OverDueDocumentDialogComponent implements OnInit {

  dataList:any;
  overdueInfo: any;
  dialog:LsMdDialog<OverDueDocumentDialogComponent>;

  constructor(    private infoService: ItemInfoRFIDService,
    ) { }

  ngOnInit(): void {
    if(this.dataList){
      this.overdueInfo = this.dataList;
    }
    console.log(this.overdueInfo,'info');    
    // this.infoService.updateDialogValue(false);
  }

  proceed(sendRequest){
    if(sendRequest){
      this.dialog.close(sendRequest);
    }
  }

  cancel(){
    this.dialog.close(null);
  }
}
