// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = { 
  production: false,
  APP_CONFIG: 'app.dev.json',
  service_url: 'http://192.200.1.219:8002',
  rfid_url: 'http://192.200.1.219:8085/RFID/RFIDServer',
  fingerprint_url: 'http://192.200.1.219:8085/layer/biometric' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
