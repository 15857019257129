import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { AppConstants } from './common/constants/app.constants';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { DashboardComponent } from './common/components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService as AuthGuard } from 'src/app/common/service/auth-guard.service';
// import { SelfModeWithoutCardGuardService } from './common/service/self-mode-withoutCard-guard.service';
// import { SelfModeWithCardGuardService } from './common/service/self-mode-withCard-guard.service';
import { SelfModeGuardService as SelfGuard } from './common/service/self-mode-guard.service';
import { CheckoutDeactivateGuard as CheckoutGuard} from './common/service/checkout-deactivate.guard';

const routes: Routes = [
  { 
    path: AppConstants.routes.login, component: LoginComponent
  },
  // {
  //   path: AppConstants.routes.self, loadChildren: () => import('./modules/features/self/self.module').then(mod => mod.SelfModule),
  //   canActivate: [SelfModeWithoutCardGuardService]
  // },
  {
    path: AppConstants.routes.dashboard, component: HomePageComponent, children: [
      {
        path: '', component: DashboardComponent
      },
      {
        path: AppConstants.routes.checkIn, loadChildren: () => import('./modules/features/check-in/check-in.module').then(mod => mod.CheckInModule) ,canDeactivate: [CheckoutGuard]
      },
      {
        path: AppConstants.routes.checkOut, loadChildren: () => import('./modules/features/check-out/check-out.module').then(mod => mod.CheckOutModule) ,  canDeactivate: [CheckoutGuard]
      },
      {
        path: AppConstants.routes.renew, loadChildren: () => import('./modules/features/renew/renew.module').then(mod => mod.RenewModule)
      },
      {
        path: AppConstants.routes.biometric, loadChildren: () => import('./modules/features/biometric/biometric.module').then(mod => mod.BiometricModule)
      },
      {
        path: AppConstants.routes.databaseQuery, loadChildren: () => import('./modules/features/database-query/database-query.module').then(mod => mod.DatabaseQueryModule)
      },
      { 
        path: AppConstants.routes.fine, loadChildren: () => import('./modules/features/fine/fine.module').then(mod => mod.FineModule)
      },
      {
        path: AppConstants.routes.monitoringRfid, loadChildren: () => import('./modules/features/monitoring-rfid/monitoring-rfid.module').then(mod => mod.MonitoringRFIDModule)
      },
      {
        path: AppConstants.routes.patron, loadChildren: () => import('./modules/features/patron/patron.module').then(mod => mod.PatronModule)
      },
      {
        path: AppConstants.routes.sessionLogs, loadChildren: () => import('./modules/features/session-logs/session-logs.module').then(mod => mod.SessionLogsModule)
      },
      {
        path: AppConstants.routes.settings, loadChildren: () => import('./modules/features/settings/settings.module').then(mod => mod.SettingsModule)
      },
      {
        path: AppConstants.routes.tagging, loadChildren: () => import('./modules/features/tagging/tagging.module').then(mod => mod.TaggingModule)
      },
      {
        path: AppConstants.routes.self, loadChildren: () => import('./modules/features/self/self.module').then(mod => mod.SelfModule),
        canActivate: [SelfGuard]
      }
    ], canActivate: [AuthGuard]
  },
  {
    path: '', redirectTo: '/login', pathMatch: 'prefix'
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


//61.  canActivate: [AuthGuard, SelfModeWithCardGuardService]