import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { HttpClient } from '@angular/common/http';
import { AnyARecord } from 'dns';

/**
 * @class ItemInfoRFIDService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class  ItemInfoRFIDService {

  public showDialogBS:BehaviorSubject<any>;
  public showDialogObs:Observable<any>;

  public getBackDropDataBS:BehaviorSubject<any>;
  public getBackDropDataObs:Observable<any>;

  public dialogOpenBookDrpBS : BehaviorSubject<any>;
  public dialogOpenBookDrpOBS : Observable<any>;

  constructor(
    private httpService: HttpService,
    private _http:HttpClient,
    private loaderService : LoaderService
  ) {
    this.showDialogBS = new BehaviorSubject(undefined);
    this.showDialogObs = this.showDialogBS.asObservable();
    
    this.getBackDropDataBS = new BehaviorSubject(undefined);
    this.getBackDropDataObs = this.getBackDropDataBS.asObservable();

    this.dialogOpenBookDrpBS = new BehaviorSubject(undefined);
    this.dialogOpenBookDrpOBS = this.dialogOpenBookDrpBS.asObservable();    
   }

   updateBackDropData(value:any){
     this.getBackDropDataBS.next(value);
   }

   updateDialogValue(value:any){
     this.showDialogBS.next(value);
   }

   openDialogBackDrp(value : any){
     this.dialogOpenBookDrpBS.next(value);
   }
    /**
     * This method used to get item information
     * @param rfidResp rfid response
     * @param callback call back request
     * @param isLoader Loader shown or not
     */
  getItemInfoRFID(rfidResp, callback: (data) => void, isLoader: boolean = false) {
   let url = environment.service_url + "/api/item/rfid/info";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    }, isLoader);
  }
/**
 * to get item information while checkin
 * @param rfidResp rfid response
 * @param callback call back request
 */
  getItemInfoRFIDcheckIn(rfidResp, callback: (data) => void) {
    // let url = environment.service_url + "/api/item/rfid/info";
    let url = environment.service_url+"/api/circulation/rfid/checkin";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
  /**
 * to get item information while checkin
 * @param rfidResp rfid response
 * @param callback call back request
 */
getItemInforBackDropcheckIn(rfidResp,callback: (data: string) => void, error? : any,showLoader: boolean = false,unsub?: (data: any) => void) {
  let rfidUrl = environment.service_url+"/api/circulation/rfid/backdropCheckin";
  // let requestParams = new Map();
  // this.httpService.post(url, rfidResp, requestParams, (resp) => {
  //   callback(resp);
  // }, (error) => {
  //   console.log(error);
  // });
  if(showLoader){
    this.loaderService.show();
  }
   let ref= this._http.post(rfidUrl,rfidResp)
   .pipe(catchError(err =>{
    if(showLoader){
      this.loaderService.hide();
    } 
    if(error)
    error(err)
  return  throwError( err || "some error occured")
  }))
  .subscribe((resp: string) => {
    if(showLoader){
      this.loaderService.hide();
    }
    callback(resp);
  }, err => {
    if(error)
    error(err);
  });
  if(unsub)
  unsub(ref)
}
/**
 * to get item information while renew
 * @param rfidResp rfid response
 * @param callback call back request
 */
  getItemInfoRFIDrenew(rfidResp, callback: (data) => void) {
    // let url = environment.service_url + "/api/item/rfid/info";
    let url = environment.service_url+"/api/circulation/rfid/renew";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
/**
 * to get item information while manual renew
 * @param rfidResp rfid response
 * @param callback call back request
 */
  getManualInfoRFIDrenew(rfidResp, callback: (data) => void) {
    // let url = environment.service_url + "/api/item/rfid/info";
    let url = environment.service_url+"/api/circulation/renew";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
/**
 * to get item information while manual checkin
 * @param rfidResp rfid response
 * @param callback call back request
 */
  getManualInfoRFIDcheckIn(rfidResp, callback: (data) => void) {
    // let url = environment.service_url + "/api/item/rfid/info";
    let url = environment.service_url+"/api/circulation/checkin";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
/**
 * to get patron info
 * @param rfidResp rfid response
 * @param callback call back request
 */
  getPatronInfo(rfidResp, callback: (data) => void) {
    // let url = environment.service_url + "/api/item/rfid/info";
    let url = environment.service_url+"/api/patron/info";
    let requestParams = new Map();
    this.httpService.post(url, rfidResp, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
}
