<div class="okPopUp-container">
    <div fxLayoutAlign="center center" class="close-btn" (click)="close()">
        <span class="fa fa-close"></span>
    </div>
    <div fxLayout="row">
        <div fxLayoutAlign="center center">
            <img class="imgPnl" [src]="dataList?.error? errorImg: infoImg">
        </div>
        <div class="error_message">
            <p id="mainText" [innerHtml]="dataList?.mainText| safeHtml" [ngClass]="{'error':dataList?.error, 'info': !dataList?.error }"></p>
            <p id="subText" [innerHtml]="dataList?.subText| safeHtml"></p>
        </div>

    </div> 
</div>