<div class = "mainContainer">
    <div class="upHeader" fxLayout="row">

        <div class = "header" fxLayoutAlign="start center">
            Renewal Status
        </div>
        <div fxLayoutAlign="end center" class="closeButton">
            <img (click)="closeKeyboard()" src='assets/svgs/unsuccessful_transactions.svg' width='24px' height='24px' class="closeBtn">
        </div>
    </div>
    <div class="table-div"> 
        <div class="inner1" fxFlex="100%" fxLayout="column">
            <div class="dbHeader sm-medium-font" fxFlex="0 0 12%" fxLayout="row">
                <div fxFlex="8%" fxLayoutAlign="start center">Item ID</div>
                <div fxFlex="35%" fxLayoutAlign="start center">Title</div>
                <div fxFlex="8%" fxLayoutAlign="start center">Type</div>
                <div fxFlex="13%" fxLayoutAlign="start center">Due Date</div>
                <div fxFlex="36%" fxLayoutAlign="start center">Renewal Status</div>
            </div>
            <div class="contentHolder sm-font" fxFlex="100%" fxLayout="column">
                <div class="content" fxFlex="0 0 14%" fxLayout="row" *ngFor="let arr of tableArr;" (click)="(initialResponseData.renewWithoutBook )?  (arr.selected=!arr.selected ): null;" [class.active]="arr?.selected">
                    <div fxFlex="8%" fxLayoutAlign="start center">{{arr.itemId || arr.item_identifier}}</div>
                    <div fxFlex="35%" fxLayoutAlign="start center">{{arr.title || arr.title}}</div>
                    <div fxFlex="8%" fxLayoutAlign="start center">{{arr.itemType || arr.media_type}}</div>
                    <div fxFlex="13%" fxLayoutAlign="start center">{{arr.dueDate || arr.due_date}}</div>
                    <div fxFlex="36%" fxLayoutAlign="start center" *ngIf="arr?.ok==1"><img src='assets/svgs/check_circle.svg' width='24px' height='24px'>{{arr.screen_msg}}</div>
                    <div fxFlex="36%" fxLayoutAlign="start center" *ngIf="arr?.ok==0 || arr?.ok==2"><img src='assets/svgs/unsuccessful_transactions.svg' width='24px' height='24px'>{{arr.screen_msg}}</div>
                </div>
            </div>
        </div> 
    </div>

</div>