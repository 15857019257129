import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/common/service/http.service';
import { SelfPatronService } from 'src/app/service/self-patron.service';
import { SelfPatron } from 'src/app/common/model/selfPatron.dto';

/**
 * @class ReuseDataService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class ReuseDataService { 
  /**
   * selfPatronState observable
   */
  SelfPatronState: Observable<SelfPatron>;
  /**
   * SelfPatronState behavious subject
   */
  private SelfPatronStateBS: BehaviorSubject<SelfPatron>;

  SelfBack: Observable<any>;
  private SelfBackBS: BehaviorSubject<any>;
  /**
   * used to Reuse data
   */
  constructor() { 
    this.SelfPatronStateBS = <BehaviorSubject<SelfPatron>>new BehaviorSubject<SelfPatron>(null);
    this.SelfPatronState = this.SelfPatronStateBS.asObservable();

    this.SelfBackBS = <BehaviorSubject<any>>new BehaviorSubject<any>(null);
    this.SelfBack = this.SelfBackBS.asObservable();
    
  }
  /**
   * Update self patron state
   * @param data patron information or null
   */
  updateSelfPatronState(data: SelfPatron | null)  {
    this.SelfPatronStateBS.next(Object.assign({}, data));
  }
  updateSelfBack(data){
    this.SelfBackBS.next(data);
  }
}
