import { InitialResponseDTO } from '../model/initial-response.dto';

export class AppConstants {

    public static global = class {
        public static time_out = 5000;
        public static LOOP_DELAY = 0;
        public static LOOP_INTERVAL = 3000;
        public static ERROR_MSG_TIMEOUT = 2000;
        public static initialRequestData: InitialResponseDTO = new InitialResponseDTO();
    }

    public static replyErrType = class {
        public static err = 0;
        public static info = 1;
    }

    public static SPECIAL_KEYCODES = [38, 40, 13, 8]

    public static routes = class {
        public static checkIn = 'check-in';
        public static checkOut = 'check-out';
        public static renew = 'renew';
        public static biometric = 'biometric';
        public static smartcard = "smartcard";
        public static databaseQuery = 'database-query';
        public static databaseQueryDetails = 'details';
        public static fine = 'fine';
        public static monitoringRfid = 'monitoring-rfid';
        public static patron = 'patron';
        public static sessionLogs = 'session-logs';
        public static settings = 'settings';
        public static tagging = 'tagging';
        public static dashboard = 'dashboard';
        public static login = 'login';
        public static newtag = 'new-tag';
        public static retag = 'retag';
        public static untag = 'untag';
        public static oldtag = 'old-tag';
        public static patronTagging = 'tagging';
        public static patronMonitoring = 'monitoring';
        public static patronDatabase = 'database';
        public static registerFingerPrint = 'register-fingerprint';
        public static removeFingerPrint = 'remove-fingerprint';
        public static verifyFingerPrint = 'verify-fingerprint';
        public static patronAccount = 'patron-account';
        public static self = 'self';
        public static selfPatron = 'self-patron';
        public static myAccount = 'my-account';

        public static selfCheckin = "self-checkin";
        public static selfCheckout = "self-checkout";
        public static selfRenew = "self-renew";
        public static barcodeLogin = 'barcode-login';
        public static patronDetails = 'patron-details';
        public static fineDetails = 'fine-details';
        public static options = 'options';
        public static queryParams = class {
            public static biometric = 'biometric';
        }
    }

    public static infoLabels = class {
        public static rfidReadingLabel = 'check-in';
        public static putTagLabel = "Put the tag on Sensor/Reader to Monitor";
        public static putPatronIdCardLabel = "Put Patron ID Card on Sensor/Reader to Monitor";
        public static putFingerprintLabel = "Put Fingerprint on Sensor/Reader to Monitor";
        public static freshTagLabel = "Fresh Tag Found !";
        public static itemTaggedSuccessLabel = "Item Tagged Successfully";
        public static itemRetaggedSucessLabel = "Item Re-tagged Successfully";
        public static itemUntaggedSucessLabel = "Item Untagged Successfully";
        public static notFreshTag = "Not a Fresh Tag !";
        public static logOut = "Please Click on Exit to Logout ";
        public static memberNotFound = "Member does not exist"
        public static freshPatronId = "Fresh ID Found !";
        public static readingItem = "Reading Item Tags";
        public static sentOtp = "Dear Patron, Your OTP has been sent to your Mobile number";
        public static enterOtp = "Enter your OTP to proceed"
    }

    public static responseCodeMessages = class {
        public static MULTIPLE_TAGS = "Multiple Tags Found!";
        public static MULTIPLE_BOOKS = "Multiple Books Found";
        public static NO_BOOK = "No Book on the sensor!";
        public static MEMBER_CARD_FOUND = "Member Card Found";
        public static BOOK_FOUND = "Book Found";
        public static INVALID_TAG = "Invalid Tag!";
        public static RFID_SERVER_NOT_CONNECTED = "RFID server not connected!";
        public static FRESH_TAG_FOUND = "Fresh Tag Found";
        public static TAGGING_FAILED = "Tagging Failed";
        public static TAGGING_SUCCESSFUL = "Tagging Successful";
        public static TAG_REPLACED = "Tag Replaced";
        public static NO_TAG_FOUND = "No Tag Found";
        public static ITEM_UNTAGGED = "Item Untagged";
        public static ITEM_UNTAGGING_FAILED = "Item Untagging Failed!";
        public static NOT_FRESH_TAG = "Not a fresh tag!";
        public static ITEM_INITIALIZED = "Item Initialized";
        public static MEMBER_CARD_NOT_FOUND = "Member Card Not Found!";
        public static CARD_ALREADY_ISSUED = "Card already issued.";
        public static MULTIPLE_MEMBER_CARD = "Multiple Memeber Card";
        public static REQUEST_NOT_PROCESSED = "Request not processed!";
        public static READER_DISCONNECTED = "Reader Disconnected";
        public static CHIP_ERROR_EAS = "Chip Error EAS";
        public static CHIP_ERROR_READ = "Chip Error READ";
        public static CHIP_ERROR_WRITE = "Chip Error WRITE";
        public static CHIP_ERROR_AFI = "Chip Error AFI";
        public static RFID_READER_NOT_CONNECTED = "RFID reader not connected";
        public static NO_DATA_FOUND = "NO DATA FOUND";

    }

    public static delimiters = class {
        public static urlDelimiter = "/";
        public static stringDelimiter = class {
            public static delim1 = String.fromCharCode(30);
            public static delim2 = String.fromCharCode(31);
            public static delim3 = String.fromCharCode(29);
        }
    }

    public static heading = class {
        public static reTag = "Tagging (Retag)";
        public static unTag = "Tagging (Untag)";
        public static newTag = "Tagging (New Tag)";
        public static tagging = "Tagging";
        public static databaseQuery = "Database Query";
        public static checkOut = "Check-Out";
        public static checkIn = "Check-In";
        public static renew = "Renew";
        public static patron = "Patron";
        public static monitoringRfid = "Monitoring Item";
        public static exit = "Exit";
        public static biometric = "Biometric Entry";
        public static smartcard = "Smartcard Entry";
        public static patronTagging = "Patron Tagging";
        public static patronDatabase = "Patron Database";
        public static monitoringPatron = "Monitoring Patron-Card";
        public static patronMonitoring = "Monitoring Patron-Card";

        public static self = 'Patron';
        public static selfPatron = 'Patron';
        public static myAccount = 'my-account';
        public static barcodeLogin = 'barcode-login';
    }

    public static userType = class {
        public static admin = 0;
        public static self = 1;
    }

    public static iTagInfoLabels = class {
        public static ascensionNumber = "Accession Number";
        public static status = "Status";
        public static title = "Title";
        public static author = "Author";
        public static itemType = "Item Type";
        public static callNumber = "Call Number";
        public static libsysCode = "Libsys Code";
        public static tagProtection = "Tag Protection";
    }


    public static selfIdentificationModes = class {
        public static barcode = 0;
        public static smartCard = 1;
        public static biometric = 2;
        public static biometric_smartCard = 3;
    }

    public static localStorageItems = class {
        public static initialSettings = 'inStng';
        public static loginResponse = 'lgnRsp';
        public static userType = 'usrTyp';
        public static token = 'token';
        public static userId = 'userId';
        public static otpSuccess = 'otpSuccess';
        public static NextRfidReader = 'IIRfidReader'
    }

    public static requestCodes = class {
        public static CONNECT_RFID = "RF1";
        public static SET_EAS = "RF2";
        public static CLEAR_EAS = "RF3";
        public static TAG_DETAILS = "RF5";
        public static SINGLE_ITEM_MONITOR = "RF6";
        public static PATRON_MONITOR = "RF13";
        public static INITIALIZE_TAG = "RF16";
        public static INITIALIZE_PATRON_TAG = "RF17";
        public static CHECK_READER = "RF27";
        public static BACK_DROP = "BK14";
        public static CONNECT_EM = "EM1";
        public static SET_SECURITY_BIT_EM = "EM2";
        public static CLEAR_SECURITY_BIT_EM = "EM3";
        public static TOGGLE_SECURITY_BIT_EM = "EM4";
        public static MONITOR_EM = "EM5";
        public static DISCONNECT_EM = "EM6";
    }

    public static responseCodes = class {
        public static MULTIPLE_TAGS = "#301";
        public static MULTIPLE_BOOKS = "#100";
        public static NO_BOOK = "#101";
        public static MEMBER_CARD_FOUND = "#102";
        public static BOOK_FOUND = "#103";
        public static INVALID_TAG = "#143";
        public static RFID_SERVER_NOT_CONNECTED = "#104";
        public static FRESH_TAG_FOUND = "#105";
        public static TAGGING_FAILED = "#106";
        public static TAGGING_SUCCESSFUL = "#107";
        public static TAG_REPLACED = "#108";
        public static NO_TAG_FOUND = "#109";
        public static ITEM_UNTAGGED = "#110";
        public static ITEM_UNTAGGING_FAILED = "#111";
        public static NOT_FRESH_TAG = "#112";
        public static ITEM_INITIALIZED = "#113";
        public static MEMBER_CARD_NOT_FOUND = "#201";
        public static MULTIPLE_MEMBER_CARD = "#202";
        public static REQUEST_NOT_PROCESSED = "#800";
        public static READER_DISCONNECTED = "#802";
        public static CHIP_ERROR_EAS = "#803";
        public static CHIP_ERROR_READ = "#804";
        public static CHIP_ERROR_WRITE = "#805";
        public static CHIP_ERROR_AFI = "#806";
        public static RFID_READER_NOT_CONNECTED = "#807";
        public static OLD_TAG_FE = "#99999";  //fe used
    }





    public static ITEM_TYPE = new Map().set(0, "Books").set(1, "Journal").set(2, "Loose Issue").set(3, "Member")
    public static ITEM_REV_TYPE = new Map().set("Books", "0").set("Book", "0").set("Journal", "1").set("Loose Issue", "3").set("Member", "3")
    public static RFID_TO_ITEM_TYPE = new Map().set(1, 0).set(4, 1).set(5, 2)

    public static responsCodeErrorMap = new Map<string, object>().set("#301", { message: "Multiple Tags Found !", color: "red" })
        .set("#100", { message: "Multiple Books Found", color: "red" })
        .set("#101", { message: "No Book on the sensor!", color: "red" })
        .set("#102", { message: "Member Card Found", color: "red" })
        .set("#103", { message: "Book Found", color: "red" })
        .set("#143", { message: "Invalid Tag!", color: "red" })
        .set("#104", { message: "RFID server not connected!", color: "red" })
        .set("#105", { message: "Fresh Tag Found !", color: "green" })
        .set("#106", { message: "Tagging Failed", color: "red" })
        .set("#107", { message: "Item Tagged Successfully", color: "green" })
        .set("#108", { message: "Tag Replaced", color: "red" })
        .set("#109", { message: "No Tag Found", color: "red" })
        .set("#110", { message: "Item Untagged", color: "green" })
        .set("#111", { message: "Item Untagging Failed!", color: "red" })
        .set("#112", { message: "Not a Fresh Tag !", color: "red" })
        .set("#113", { message: "Item Initialized", color: "green" })
        .set("#201", { message: "Member Card Not Found!", color: "red" })
        .set("#202", { message: "Multiple Member Card", color: "red" })
        .set("#800", { message: "Request not processed!", color: "red" })
        .set("#802", { message: "Reader Disconnected", color: "red" })
        .set("#803", { message: "Chip Error EAS", color: "red" })
        .set("#804", { message: "Chip Error READ", color: "red" })
        .set("#805", { message: "Chip Error WRITE", color: "red" })
        .set("#806", { message: "Chip Error AFI", color: "red" })
        .set("#807", { message: "RFID reader not connected", color: "red" });

    // public static ITEM_TYPE = new Map().set("Multiple Tags Found!", "#301")

    public static patronInfoDetails = class {
        public static patronID = "Patron ID";
        public static patronName = "Name";
        public static patronAlternateId = "Alternate ID";
        public static patronCategory = "Category";
        public static patronValidUpto = "Valid Upto";
        public static patronLateFeesDues = "Late Fees Dues";
        public static patronRemarks = "Remarks";
        public static patronLSCode = "LS Code";

    }
    public static verificatinDetails = class {
        public static verificationData: any = [];
    }
    public static withMember = class {
        public static withMember: boolean = false;
        public static withMemberRenew: boolean = false;
        public static arrSelected: boolean;
        public static arrSelectedRenew: boolean;
    }

}

