import { Enum } from 'typescript-string-enums';
import { Directive } from '@angular/core';
/**
 * Button types
 */
export const ButtonVariants = Enum({
    flat: "flat",
    default: "flat",
    raised: "raised",
    fab: "fab",
    miniFab: "mini-fab",
    icon: "icon",
    iconText: "icon-text"
});

export type ButtonVariants = Enum<typeof ButtonVariants>;



