<div class= "mainContainer">
    <div *ngIf="!biometricSuccessfull && !verificationProcess" class ="biometricHeading" fxLayoutAlign="start center"> 
        Scanned count {{start}} of {{end}}
    </div>
    <div *ngIf="biometricSuccessfull && !verificationProcess" class ="biometricHeading" fxLayoutAlign="start center"> 
            Biometric Registration Successful
    </div>
    <div *ngIf="verificationProcess" class="biometricHeading" fxLayoutAlign="start center">
            Biometric Verification
    </div>
    <div *ngIf="!rfidNotworking" class = "info" fxLayoutAlign="center center">
        {{message}}  
    </div>
    <div *ngIf="rfidNotworking" class="warn" fxLayoutAlign="center center">
        {{message}}
    </div> 
    <!-- <p>{{errorMsg}}</p> -->
    <div *ngIf="!showMoreScan && !savaInformation && !biometricSuccessfull" class="footer-div" fxLayout="row">
            <div *ngIf="!cancelActionChanged" class="left_btn">
                    <ls-button (click)="cancelButton();"> 
                        <span class="btn-text  md-medium-font">CANCEL</span>
                    </ls-button>
            </div>
            <div *ngIf="cancelActionChanged" class="left_btn">
                    <ls-button (click)="cancelAfterScanMore();">
                        <span class="btn-text  md-medium-font">CANCEL</span>
                    </ls-button>
            </div>
            <div class="right_btn">
                    <ls-button (click)="confirmButton();">
                        <span class="btn-text  md-medium-font">CONFIRM</span>
                    </ls-button>
            </div>
    </div>
    <div *ngIf="showMoreScan && !biometricSuccessfull" class="footer-div" fxLayout="row">
            <div class="left_btn"> 
                    <ls-button (click)="registerUser();">
                        <span class="btn-text  md-medium-font">No</span>
                    </ls-button>
            </div>
            <div class="right_btn">
                    <ls-button (click)="scanMore();">
                        <span class="btn-text  md-medium-font">Yes</span>
                    </ls-button>
            </div>
    </div>
    <div *ngIf="savaInformation" class="footer-div" fxLayout="row">
            <div class="left_btn">
                    <ls-button (click)="ShowUserNotRegistered();">
                        <span class="btn-text  md-medium-font">No</span>
                    </ls-button>
            </div>
            <div class="right_btn">
                    <ls-button (click)="userRegistered();">
                        <span class="btn-text  md-medium-font">Yes</span>
                    </ls-button>
            </div>
    </div>
    <div *ngIf="biometricSuccessfull" class="footer-div" fxLayout="row">
            <div class="left_btn">
                    <ls-button (click)="showSuccessMessage();">
                        <span class="btn-text  md-medium-font">No</span> 
                    </ls-button>
            </div>
            <div class="right_btn">
                    <ls-button (click)="navigateToVerification();">
                        <span class="btn-text  md-medium-font">Yes</span>
                    </ls-button>
            </div>
    </div>
    <div *ngIf="verificationProcess" class="footer-div" fxLayout="row">
        <div class="left_btn">
            <ls-button (click)="cancelVerification();">
                <span class="btn-text md-medium-font">CANCEL</span>
            </ls-button>
        </div>
        <div class="right_btn">
            <ls-button (click)="verifiedUser();">
                <span class="btn-text md-medium-font">CONFIRM</span>
            </ls-button>
        </div>
    </div>
</div>
