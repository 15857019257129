import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ViewChild } from '@angular/core';
/**
 * Provides pagination control display.
 */
@Component({
  selector: 'ls-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})
export class PagingComponent implements OnInit {
  /**
   * Flag for display first and last index.
   */
  @Input('showFirstLast') showFirstLast: boolean;
  /**
   * Default selected intial page
   */
  @Input('initialPage') initialPage: number;
  /**
   * Flag for displaying the range
   */
  @Input('showRange') showRange: boolean;
  /**
   * Display flag for Goto 
   */
  @Input('showGoTo') showGoTo: boolean;
  /**
   * Amount of page navigation links for the paging bar. Defaults to 0.
   */
  @Input('linkCount') linkCount: number;
  /**
   * Selected page size for the pagination. Defaults to 50.
   */
  @Input('pageSize') pageSize: number;
  /**
   * Total rows for the pagination.
   */
  @Input('total') total: number;
  /**
   *  Method to be executed when page size changes or any button is clicked in the paging bar.
   */
  @Output('pageChange') pageChanged = new EventEmitter<any>();
  /**
   * Paging Bar element reference.
   */
  @ViewChild('pagingBarLinks', {static: true}) pagingBarLinks;

  /**
   * Constructor
   */
  constructor() { }
  /** */
  ngOnInit() {
  }
  /**
   * Tracks the changes on the page and navigate to the particular page number
   * @param changes 
   */
  ngOnChanges(changes) {
    if(changes.initialPage && this.initialPage && this.pagingBarLinks){
      this.pagingBarLinks.navigateToPage(this.initialPage);
    }
  }
  /**
   * Method to be executed when page size changes or any button is clicked in the paging bar 
   * @param event 
   */
  pageChange(event) : void {
    this.pageChanged.emit(event);
  }

}
