<div class="drop-container">
    <div class="drop-table">
        <div class="header">
            <div class="header-data" *ngFor='let header of headerList;let i = index;' [style.width.%]='headerWidth[i]'>
                {{header}}
            </div>
        </div>
        <div class="drop-content">
            <div class="content" *ngFor='let item of contentArray;let j = index;'>
                    <span  class="f-center regular f15" style="width:10%"> {{item.item_identifier}}</span>
                    <span  class="f-center regular f15" style="width:43%"> {{item.title}} </span>
                    <span  class="f-center regular f15" style="width:10%"> {{item.media_type}}</span>
                    <span  class="f-center regular f15" style="width:24%"> {{item.screen_msg}}</span>
                    <!-- <span  class="f-center regular f15" style="width:8%"> {{item.totalFine}}</span> -->
                    <span  class="f-center regular f15" style="width:13%"> {{item.patron_identifier}}</span>
            </div>
        </div>
    </div>
    <div class="btn-container">
        <div class="left-btn" style="padding-right: 20px;">
            <ls-button [type]="'reset'" (click)='cancel()'>Cancel</ls-button>
        </div>
        <div class="right-btn">
            <ls-button type='submit' (click)='printSlip()'>Print</ls-button>
        </div>
    </div>
</div>