import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AppState } from './app-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { InitialResponseDTO } from '../model/initial-response.dto';
import { AppConstants } from '../constants/app.constants';
/**
 * @class AppStateService
 * @author Saurabh bajpai
 */
@Injectable()
export class AppStateService {
  /**
   * appStateModel observable of appState
   */
  appStateModel: Observable<AppState>;
  /**
   * Behavior subject
   */
  private appBehaviour: BehaviorSubject<AppState>;
  /**
   * appState object contains appStateModel
   */
  private appState: {
    appStateModel: AppState
  };
  /**
   * @constructor function
   * @param platformId 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.appState = { appStateModel: new AppState() };
    this.appBehaviour = <BehaviorSubject<AppState>>new BehaviorSubject(new AppState());
    this.appStateModel = this.appBehaviour.asObservable();
  }
  /**
   * This method used to update the appState
   * @param appState 
   */
  update(appState: AppState) {
    this.appState.appStateModel = Object.assign({},appState);
    this.appBehaviour.next(Object.assign({}, this.appState).appStateModel);
    //console.log(this.appState.appStateModel.isInitialRespErr); 
  }
  /**
   * This method used to get items from local storage
   * @param item 
   * @param parse 
   */
  getItemFromLocalStorage(item, parse: boolean = true): any | string | Object {
    if (isPlatformBrowser(this.platformId)) {
      let res = localStorage.getItem(item);
      if (res && res != "undefined" && res != "null" && parse) {
        res = JSON.parse(res);
      }
      return res;
    }
  } 
  /**
   * This item used to remove item from local storage
   * @param item 
   */
  removeItemFromLocalStorage(item) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(item)) {
        localStorage.removeItem(item);
      }
    } 
  }

  /**
   * This method used to set item to local storage
   * @param itemName 
   * @param itemValue 
   * @param parse need to use JSON.stringyfy
   */
  setItemToLocalStorage(itemName, itemValue, parse: boolean = true) {
    if (parse) {
      localStorage.setItem(itemName, JSON.stringify(itemValue));
    } else {
      localStorage.setItem(itemName, itemValue);
    }
  }
  /**
   * This method used to trim dollar symbol from a string
   * @param str 
   */
  trimDollar(str: string): string {
    if (typeof str === 'string' &&  str.includes('$')) {
      return str.split('$')[1];
    } else {
      return str;
    }
  }
 /**
  * This method used to get item type with their respective code
  * @param itemCode 
  */
  getItemType(itemCode: string) {
    switch (itemCode) {
      case "01":
        {
          return "Book";
        }
      case "02":
        {
          return "Member Card";
        }
      case "03":
        {
          return "Journal";
        }
      case "06":
        {
          return "Loose Issue";
        }
      default:
        {
          return "Unknown";
        }
    }
  }
  /**
   * This method used to set initial response to local storage
   * @param data 
   */
  setInitialResponseToLocalStorage(data: InitialResponseDTO) {
    AppConstants.global.initialRequestData=data;
    this.setItemToLocalStorage(AppConstants.localStorageItems.initialSettings, data, true);
  }

}