import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../constants/app.constants';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppStateService } from './appState.service';
import { LoaderService } from './loader.service';

/**
 * @class RFIDLayerService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
/**
 * This service is available as a injectable class to established connection with the RFID layer by using these services
 */
export class RFIDLayerService {
  /**
   * To store rfid url
   */
  // comment this before commit
  // rfidUrl = environment.rfid_url;
  /**
   * @constructor function
   * @param _http HttpClient service will be injected into the component
   * @param appState AppState service will be injected into the component
   * @param loaderService LoaderService will be injected into the component
   */
  constructor(
    private _http: HttpClient,
    private appState: AppStateService,
    private loaderService: LoaderService
  ) { }

    /**
     * this method used to @throws errors
     * @param error 
     */
  errorHandlers(error: Response) {

    console.log(error);
    return throwError(error || 'Error Occured');
  }
  /**
   * This method used to check weather is their any items available on RFID layer or not.
   * @param rfidRequestVariable 
   * @param callback 
   * @param showLoader 
   */
  rfidLayerBookStatus(rfidRequestVariable,callback: (data: string) => void,showLoader:boolean=false) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
      this._http.post(rfidUrl, rfidRequestVariable, { headers: {"no-auth":"true"}, responseType: 'text' }).pipe(catchError(error=>{
        console.log(error);
        if(showLoader){
          this.loaderService.hide();
        }
        return throwError(error || 'Error Occured');
      })).subscribe((resp: string) => {
        if(showLoader){
          this.loaderService.hide();
        }
        callback(resp);
      });
    }

    /**
     * This method used to check RFID layer is working or not
     * @param callback 
     * @param showLoader 
     */
  rfidLayerStatus(callback: (data: string) => void, showLoader: boolean = false,unsub?: (data: any) => void) {
  let rfidUrl = environment.rfid_url;
  if(showLoader){
    this.loaderService.show();
  }
   let ref= this._http.post(rfidUrl, AppConstants.requestCodes.CONNECT_RFID, { headers: {"no-auth":"true"}, responseType: 'text' }).pipe(
      catchError(err =>{ 
        if(showLoader){
          this.loaderService.hide();
        }
        if(unsub)
        unsub(ref)
        callback(null)
      return   throwError( err || "some error occured")
     
      })
    ).subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      if(unsub)
      unsub(ref)
      callback(resp);
    });
  }
  rfidLayerCheckReader(callback: (data: string) => void, showLoader: boolean = false,unsub?: (data: any) => void) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
     let ref= this._http.post(rfidUrl, AppConstants.requestCodes.CHECK_READER, { headers: {"no-auth":"true"}, responseType: 'text' }).pipe(
        catchError(err =>{ 
          if(showLoader){
            this.loaderService.hide();
          }
          if(unsub)
          unsub(ref)
          callback(null)
        return   throwError( err || "some error occured")
       
        })
      ).subscribe((resp: string) => {
        if(showLoader){
          this.loaderService.hide();
        }
        if(unsub)
        unsub(ref)
        callback(resp);
      });
    }

    rfidLayerForBackDrop(callback: (data: string) => void, error? : any,showLoader: boolean = false,unsub?: (data: any) => void) {
      let rfidUrl = environment.rfid_url;
      if(showLoader){
        this.loaderService.show();
      }
       let ref= this._http.post(rfidUrl, AppConstants.requestCodes.BACK_DROP, { headers: {"no-auth":"true"}, responseType: 'text' })
       .pipe(catchError(err =>{
        if(showLoader){
          this.loaderService.hide();
        } 
        if(error)
        error(err)
      return  throwError( err || "some error occured")
      }))
      .subscribe((resp: string) => {
        if(showLoader){
          this.loaderService.hide();
        }
        callback(resp);
      }, err => {
        if(error)
        error(err);
      });
      if(unsub)
      unsub(ref)
    }
    
  rfidEmSecurityLayerStatus(callback: (data: string) => void, showLoader: boolean = false,unsub?: (data: any) => void) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
     let ref= this._http.post(rfidUrl, AppConstants.requestCodes.CONNECT_EM, { headers: {"no-auth":"true"}, responseType: 'text' }).pipe(
        catchError(err =>{ 
          if(showLoader){
            this.loaderService.hide();
          }
          if(unsub)
          unsub(ref)
          callback(null)
        return   throwError( err || "some error occured")
       
        })
      ).subscribe((resp: string) => {
        if(showLoader){
          this.loaderService.hide();
        }
        if(unsub)
        unsub(ref)
        callback(resp);
      });
    }
  /**
   * This method used to monitor single item and get their response
   * @param callback 
   * @param error 
   * @param showLoader 
   */
  rfidSingleItemMonitorResponse(callback: (data: string) => void, error?:any, showLoader:boolean = true) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
    this._http.post(rfidUrl, AppConstants.requestCodes.SINGLE_ITEM_MONITOR, { headers: {"no-auth":"true"}, responseType: "text" })
    .pipe(catchError(err =>{
      if(showLoader){
        this.loaderService.hide();
      } 
      error(err)
    return   throwError( err || "some error occured")
    }))
    .subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      } 
       callback(resp) }); 
  } 

 /**
   * This method used to monitor single item and get their response
   * @param callback 
   * @param error 
   * @param showLoader 
   */
  emSingleItemMonitorResponse(callback: (data: string) => void, error?:any, showLoader:boolean = true) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
    this._http.post(rfidUrl, AppConstants.requestCodes.MONITOR_EM, { headers: {"no-auth":"true"}, responseType: "text" })
    .pipe(catchError(err =>{
      if(showLoader){
        this.loaderService.hide();
      } 
      error(err)
    return   throwError( err || "some error occured")
    }))
    .subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      } 
       callback(resp) }); 
  } 
  /**
   * This method used to get rfid tag details
   * @param callback 
   * @param error 
   * @param unsub 
   * @param showLoader 
   */
  rfidTagDetails(callback: (data: any) => void, error?: any, unsub?: (data: any) => void, showLoader:boolean = true) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
    let ref= this._http.post(rfidUrl, AppConstants.requestCodes.TAG_DETAILS, { headers: {"no-auth":"true"}, responseType: "text"})
    .pipe(catchError(err =>{
      if(showLoader){
        this.loaderService.hide();
      } 
      if(error)
      error(err)
    return  throwError( err || "some error occured")
    }))
    .subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      callback(resp);
    }, err => {
      if(error)
      error(err);
    });
    if(unsub)
    unsub(ref)
  }
  /**
   * This method used to initialize tag on available on RFID layer
   * @param callback 
   * @param showLoader 
   */
  rfidInitializeTag(callback: (data: string) => void, showLoader: boolean = false) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
    this._http.post(rfidUrl, AppConstants.requestCodes.PATRON_MONITOR, { headers: { 'no-auth': 'true' }, responseType: "text" }).subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      callback(resp);
    });
  }
  /**
   * This method used to monitor patron card on rfid layer
   * @param callback 
   * @param error 
   * @param unsub 
   * @param showLoader 
   */
  rfidPatronMonitor(callback: (data: any) => void, error?: any, unsub?: any, showLoader: boolean = false) { 
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
   let ref= this._http.post(rfidUrl, AppConstants.requestCodes.PATRON_MONITOR, { headers: { 'no-auth': 'true' }, responseType: "text" })
    .pipe(catchError(err => {
      if(showLoader){
        this.loaderService.hide();
      }
        error(err)
        return throwError ( err || "some error occured" )
      }))
    .subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      callback(resp);
    }, err => {
      if(showLoader){
        this.loaderService.hide();
      }
      error(err);
    });
    if(unsub)
    unsub(ref)
  }
  /**
   * This method used to send RFID request
   * @param body 
   * @param callback 
   * @param showLoader 
   */
  rfidRequest(body, callback: (data: string) => void, showLoader: boolean = true) {
    let rfidUrl = environment.rfid_url;
    if(showLoader){
      this.loaderService.show();
    }
    this._http.post(rfidUrl, body, { headers: { 'no-auth': 'true' }, responseType: "text" }).subscribe((resp: string) => {
      if(showLoader){
        this.loaderService.hide();
      }
      callback(resp);
    }); 
  }
  /**
   * To method used to checked the tag protection
   * @param tagId 
   * @param callback 
   * @param showLoader 
   */
  rfidSetEas(tagId,callback:(data: string)=>void, showLoader: boolean = false)
  {
    let rfidUrl = environment.rfid_url;
    this._http.post(rfidUrl,AppConstants.requestCodes.SET_EAS+AppConstants.delimiters.stringDelimiter.delim3+tagId,{headers:{"no-auth":"true"},responseType:"text"}).subscribe((resp:string)=>{
      if(showLoader){
        this.loaderService.hide();
      }  
      callback(resp);
    });
  }

   /**
   * To method used to checked the tag protection
   * @param tagId 
   * @param callback 
   * @param showLoader 
   */
  emSetEas(callback:(data: string)=>void, showLoader: boolean = false)
  {
    let rfidUrl = environment.rfid_url;
    this._http.post(rfidUrl,AppConstants.requestCodes.SET_SECURITY_BIT_EM,{headers:{"no-auth":"true"},responseType:"text"}).subscribe((resp:string)=>{
      if(showLoader){
        this.loaderService.hide();
      }  
      callback(resp);
    });
  }

  /**
   * This method used to unchecked the tag protection
   * @param tagId 
   * @param callback 
   * @param showLoader 
   */
  rfidClearEas(tagId,callback:(data: string)=>void, showLoader: boolean = false)
  {
    let rfidUrl = environment.rfid_url
    this._http.post(rfidUrl,AppConstants.requestCodes.CLEAR_EAS+AppConstants.delimiters.stringDelimiter.delim3+tagId,{headers:{"no-auth":"true"},responseType:"text"}).subscribe((resp:string)=>{
      if(showLoader){ 
        this.loaderService.hide();
      }
      callback(resp);
    });
  }

  emClearEas(callback:(data: string)=>void, showLoader: boolean = false)
  {
    let rfidUrl = environment.rfid_url
    this._http.post(rfidUrl,AppConstants.requestCodes.CLEAR_SECURITY_BIT_EM,{headers:{"no-auth":"true"},responseType:"text"}).subscribe((resp:string)=>{
      if(showLoader){ 
        this.loaderService.hide();
      }
      callback(resp);
    });
  }
  
  // rfidAfterCheckOut(rfidRequestVariable,callback: (data: string) => void,showLoader:boolean=false) {
  //   let rfidUrl = environment.rfid_url;
  //   if(showLoader){
  //     this.loaderService.show();
  //   }
  //     this._http.post(rfidUrl, rfidRequestVariable, { headers: {"no-auth":"true"}, responseType: 'text' }).pipe(catchError(error=>{
  //       console.log(error);
  //       if(showLoader){
  //         this.loaderService.hide();
  //       }
  //       return throwError(error || 'Error Occured');
  //     })).subscribe((resp: string) => {
  //       if(showLoader){
  //         this.loaderService.hide();
  //       }
  //       callback(resp);
  //     });
  //   }
}
