import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';
/**
 * This class is used to restrict routes which are only accessible when user has logged-in.
 * Uses canActivate class of '@angular/router'.
 * @class AuthGuardService 
 */
@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object,
        private appStateService: AppStateService
    ) { }

    /**
     * If user is not logged-in , then returns false and redirect to login page o/w returns true.
     * to find-out if user is logged-in, check if login-response exists in local-storage or not
     * @method canActivate
     * @returns true/false
     */
    canActivate(): boolean { 
        if (isPlatformBrowser(this.platformId)) {
            let loginResp = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.loginResponse);
            if (loginResp) {
                return true;
            } else {
                this.router.navigate([AppConstants.routes.login]);
                return false;
            }
        }
    }

}
