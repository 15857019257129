import { LoginResponseDTO } from '../model/login.response';
import { MemberDto } from '../model/member-dto';
/**
 * This class used to store important information as shown at route level
 */
export class AppState {
    /**
     * initial Response error
     */
    isInitialRespErr: string;
    /**
     * Is Used logged in or not
     */
    isLoggedIn: boolean;
    /**
     * user type either Self or Admin
     */
    userType: number;
    /**
     * Represent Instance of LoginResponseDTO
     */
    loginResponse: LoginResponseDTO;
    /**
     * Login with usertype Self with automatic mode is on in global setting
     */
    selfModeWithCard: boolean;
    /**
     * To check popup closed or not
     */
    isPopUpClose: boolean;
    /**
     * patron logged in
     */
    patronLoggedIn: boolean;
    /**
     * Is admin wants to renew items while login with Admin mode
     */
    fromRenew: boolean;
    /**
     * Represent instance of MemberDto , used to check memberEstablished or not
     */
    memberEstablished: MemberDto;
    /**
     * Used to store previous routes when we switch between MyAccount,checkout,checkin and renew
     */
    previousRoute: string;                //previousRoute of member established 
    // Used to handle renew process
    preventRenew: boolean;
    //Array for table information
    arr: Array<any> = [];
    //Array for patron info
    arrPatronInfo: Array<any> = [];
    //Array for txnMessage
    txnMsg = "message";
    //Used for handle renew with patron Id
    preventRenewWithId: boolean;
    //Array for renew with patron Id
    arrWithId: Array<any> = [];
    //Array for maintain patron Info when patronId set to true in settings
    arrPatronInfoWithId: Array<any> = [];
    //transaction message
    txnInfo: any;
    //userId
    displayUserId:string;
    //2nd rfid resader
    nextRfidReader;
}