import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * @class UtilsService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
/**
 * 
 * @param http HttpClient wiil be injected into the component
 */
  constructor(
    private http:  HttpClient
  ) {}
/**
 * This method used while downloading the sessionlogs records
 * @param url url of the file 
 * @param name name 
 */
   downloadFile(url, name?) {
    var saveData = (function () {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = "none"
      return function (data, fileName) {
        a.href = data;
        a.target = '_blank'
        if (fileName)
          a.download = fileName;
        a.click();
        a.remove();
      }
    }())
    saveData(url, name);
  }
/**
 * This method is used while print file
 * @param url current url
 */
  printFile(url) {
    this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf'
      }), responseType: 'blob'
    }).subscribe(data => {
      data = data.slice(0, data.size, 'application/pdf');
      var blobUrl;
      blobUrl = URL.createObjectURL(data);
      var iF = document.createElement('iframe');
      iF.src = blobUrl;
      iF.style.display = 'none';
      document.body.appendChild(iF);
      iF.contentWindow.print();
    });
  }

}

