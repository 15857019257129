import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AppConstants } from 'src/app/common/constants/app.constants'
import { AppStateService } from '../../service/appState.service';
import { AppState } from '../../service/app-state';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { InitialResponseDTO } from '../../model/initial-response.dto';

/**
 * @class MenuComponent
 * @author Saurabh bajpai
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
/**
 * This component shows us Menu hamburger
 */
export class MenuComponent implements OnInit {
  /**
   * options that will be displayed in the Option HamBurgur menu
   */
  menuOption = []

   /**
   * options that will be displayed in the Option HamBurgur menu
   */
  forKsMartmenuOption = [
    {item:"User Account",icon:"assets/svgs/user_account.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.patron+"/"+AppConstants.routes.patronAccount},
    {item:"Session Log",icon:"assets/svgs/session_log.svg",path:"/"+AppConstants.routes.dashboard+"/"+AppConstants.routes.sessionLogs},
    // {item:"Fine Collection",icon:"assets/svgs/fine_collection.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.fine},
    {item:"Biometric",icon:"assets/svgs/fingerprint.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.biometric},
    {item:"Option",icon:"assets/svgs/options.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.settings+"/"+AppConstants.routes.options},
    {item:"Settings",icon:"assets/svgs/settings.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.settings},
  ]
  /**
   * Show status
   */
  status: boolean;
  /**
   * Instance of global class AppState
   */
  appState: AppState = new AppState();
  /**
   * Subscribe app state model in appState service 
   */ 
  appStateSubscription; 
  /**
   * Decides visibility of biometric
   */
  removeBiometric: boolean;
  isKsMart: any;
  /**
   * @constructor function
   * @param router Router service will be injected into the component
   * @param appStateService AppStateService will be injected into the component
   */
  constructor(
    private router: Router,
    private appStateService: AppStateService
  ) { 
     let initialResp = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings, true);
      this.removeBiometric = initialResp.adminBiometricEnable;
      this.isKsMart = JSON.parse(localStorage.getItem('inStng'));

    }
    /**
     * This method gets executed on the load of this component
     */
  ngOnInit() {
    if(!this.removeBiometric){
      this.menuOption.splice(3,1);
    }
    this.appStateSubscription = this.appStateService.appStateModel.subscribe((appState) => {
      this.appState = appState;
    });    

    this.menuOption = [
      {item:"User Account",icon:"assets/svgs/user_account.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.patron+"/"+AppConstants.routes.patronAccount,showItem:true},
      {item:"Session Log",icon:"assets/svgs/session_log.svg",path:"/"+AppConstants.routes.dashboard+"/"+AppConstants.routes.sessionLogs,showItem:true},
      {item:"Fine Collection",icon:"assets/svgs/fine_collection.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.fine,showItem:!this.isKsMart.ksmart},
      {item:"Biometric",icon:"assets/svgs/fingerprint.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.biometric,showItem:true},
      {item:"Option",icon:"assets/svgs/options.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.settings+"/"+AppConstants.routes.options,showItem:true},
      {item:"Settings",icon:"assets/svgs/settings.svg",path:AppConstants.routes.dashboard+"/"+AppConstants.routes.settings,showItem:true},
    ]
  }
  /**
   * To get menu options
   */
  get MenuOptions() {
    return this.menuOption;
  }

  // navigates to respective menu-Item's Path/URL
  // routeToOption(item) {
  //   console.log(item);
  //   this.router.navigate([item]);
  // }
  /**
   * Close menu hamburger
   */
  closePopup() {
    this.appState.isPopUpClose = true;
    this.appStateService.update(this.appState);
  }
  /**
   * Unsubscribe appStateSubscription
   */
  ngOnDestroy() {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
  }

}
