<div class="rootContainer" style="background-color:beige;" fxLayoutAlign="center center"
    *ngIf="appState?.isInitialRespErr">
    <p id="errMsg">{{appState?.isInitialRespErr}}</p>
</div>

<div class="rootContainer" *ngIf="!appState?.isInitialRespErr">
    <div class="bgDiv">
        <div class="imgDivLeft" style="background-image: url('assets/images/libraryImg.jpg')">

        </div>
        <div class="yellowDivRight">
        </div>
        <div class="mainDiv">
            <div class="textDivLeft">
                <div class="logoDiv" *ngIf='initialData.ksmart' (click)='goToDashboard()'  style="background-image: url('assets/svgs/KSmart-logo.svg')">
                </div>
                <div class="logoDiv" *ngIf='!initialData.ksmart' (click)="goToDashboard()" style="background-image: url('assets/svgs/lsmart_icon_white.svg')"></div>
                <div class="bottomDiv">
                    <div class="welcomeDiv">
                        <p class="introText top">Welcome to</p>
                    </div>
                    <div class="logo-size" *ngIf='initialData.instituteLogo'> 
                        <img height="110px" width="110px" [src]="instituteLogo" alt="">
                    </div>
                    <!-- <div class="circularLogo"> -->
                    <!-- </div> -->
                    <div class="libraryNameDiv">
                        <p class="libraryName">{{constants?.global?.initialRequestData?.libraryName | uppercase}}</p>
                        <!-- <p class="tagline">({{libraryTagline}})</p> -->
                    </div>
                </div>
            </div>
            <app-loader></app-loader>
            <div class="router-container">
                <router-outlet></router-outlet>
            </div>
            <!-- show user id name (title="User Name") -->
            <div class="display-user xlg-medium-font" *ngIf='displayUserId' >
                <i class="material-icons id-icon" >person</i>
                <div >
                    {{displayUserId | uppercase}}
                </div>
            </div>
            <!-- show hamburger when logged-in and logged-in user-type is admin -->
            <div class="hamburger" *ngIf="appState?.isLoggedIn && appState?.userType == constants?.userType?.admin">
                <app-pop-up [component]="toOpen" popupHeight="200" popupWidth="200" [popUpConfig]="popUpConfig">
                    <div class="hamburger-icon" appPopUpTrigger>
                        <i class="fa fa-bars"></i>
                    </div>
                </app-pop-up>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>