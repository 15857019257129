import { Directive, HostListener, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPopUpTrigger]'
})
export class PopUpTriggerDirective {

  triggerEvent = new EventEmitter<void>()
  
  @HostListener('click', ['$event'])

  handleHostClick(event) {
    this.triggerEvent.emit()
  }

  constructor(public ele: ElementRef) { }

}
