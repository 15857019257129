<input type="file" id="lsfileupload" [multiple]="multiple" style="display:none" (change)="upload()" [accept]="fileType" />
<div (click)="onClick()" *ngIf="customUploader==1">
    <ng-content> </ng-content>
</div>
<div *ngIf="customUploader==2">
    <input matInput placeholder="selected files" style="height:31px;" value="{{selectedFiles}}">
    <button mat-raised-button color="primary" (click)="onClick()"><i class="fa fa-cloud-upload"></i> Upload</button>
</div>

<div *ngIf="customUploader==3" [style.width]="width" [style.height]="height" [ngClass]="{'border' : !border_not_required}"
    LsDropFile (dragFileList)="uploadDragFiles($event)">
</div>