import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';
import { InitialResponseDTO } from '../model/initial-response.dto';
/**
 * @deprecated
 * @class SelfModeWithoutCardGuardService
 * @author Saurabh bajpai
 */
@Injectable()
export class SelfModeWithoutCardGuardService implements CanActivate {
/**
 * 
 * @param router Router service wiil be injected into the component
 * @param platformId inject platform ID
 * @param appStateService AppStateService wiil be injected into the component
 */
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object,
        private appStateService: AppStateService
    ) { }
/**
 * This method gets executed on manual mode is on in glbal app settings
 */
    canActivate(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            let userType = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userType);
            if (userType == AppConstants.userType.self) {
                let initialResp: InitialResponseDTO = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings);
                if (initialResp.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.barcode) {
                    return true;
                } else {
                    this.router.navigate([AppConstants.routes.dashboard]);
                    return false;
                }
            } else {
                this.router.navigate([AppConstants.routes.dashboard]);
                return false;
            }
        }
    }

}
