import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LsFileUploadService } from '../ls-file-upload.service';
import { LsDropFileDirective } from '../../../directives/ls-drop-file/ls-drop-file.directive';
/**
 * Displays the Image Uploader
 */
@Component({
  selector: 'ls-image-upload',
  templateUrl: './ls-image-upload.component.html',
  styleUrls: ['./ls-image-upload.component.css'],
  providers: [LsFileUploadService]
})
export class LsImageUploadComponent implements OnInit, AfterViewInit {
  /**
   * Render Reference for Canvas
   */
  context: CanvasRenderingContext2D;
  /**
   * Constructor
   * @param element 
   * @param dom 
   * @param uploadServices 
   */
  public constructor(private element: ElementRef, private dom: DomSanitizer, private uploadServices: LsFileUploadService) { }

  /**
   * Canvas CSS Display property
   */
  canvasDisplay = "none";
  /**
   * Reference of Render image canvas 
   */
  @ViewChild("myCanvas") myCanvas;
  /**
   * Reference of Image Drop Canvas
   */
  @ViewChild("dropCanvas") dropCanvas;
  /**
   * Image File
   */
  fileImage;
  /**
   * Image Upload type
   */
  @Input('imgUpType') imageDrop
  /**
   * Max Image Upload Size
   */
  @Input('imageSize') imageSize
  /**
   * Width of Image
   */
  @Input('width') width
  /**
   * Height of Image
   */
  @Input('height') height
  /**
   * Image upload Event emitter
   */
  @Output("files") files = new EventEmitter();
  /**
   * @ignore
   */
  resultValue;
  /**
   * Image to be render on the CANVAS
   */
  image: File;
  /**
   * Display controller of upload button.
   */
  buttonHide = "hidden";
  /**
   * If Image Type 3 Canvas is Emptied.
   */
  ngOnInit() {
    // if(this.imageDrop==3)
    // this.draw(null);
  }
  /**
   * Click Event Emitter on the image upload reference
   */
  onClick() {
    this.element.nativeElement.querySelector("#lsimageupload").click();

  }
  /**
   * 1. Selects the Image uploaded on the image uploader.
   * 2. Draws the Image on the canvas.
   * 3. If Button is hidden, display's the button.
   * 4. If uploader type is 3, emits the file.
   */
  selectImage() {
    let files = this.element.nativeElement.querySelector('#lsimageupload').files;
    let file = files[0];
    this.fileImage = file;
    this.buttonHide = "visible";
    if (this.imageDrop != 4)
      this.draw(this.fileImage);

    if (this.imageDrop == 3 || this.imageDrop == 4)
      this.files.emit(this.fileImage)
  }
  /**
   * @ignore
   */
  ngAfterViewInit() {
  }
  /**
   * @ignore
   */
  b;
  /**
   * Draws the Image on the canvas according image ratio.
   * @param image 
   */
  draw(image) {

    let canvas;
    if (this.imageDrop == '2') {
      canvas = this.dropCanvas.nativeElement;
      this.context = canvas.getContext("2d");
    }
    else {
      canvas = this.myCanvas.nativeElement;
      this.context = canvas.getContext("2d");
    }
    let tempContext = this.context;
    let img = new Image();
    let size = this.imageSize;
    img.onload = function () {
      let ratio = img.height / img.width;
      let width = size;
      let height = size * ratio;
      canvas.width = width;
      canvas.height = height;
      tempContext.drawImage(img, 0, 0, width, height);
    }
    img.src = URL.createObjectURL(image);
    this.canvasDisplay = "block";
  }

  /**
   * Image Drag -> Drop event handler. Draws the Image.
   * @param value 
   */
  uploadDragFiles(value) {
    this.buttonHide = "visible"
    this.fileImage = value[0];
    this.draw(this.fileImage);
  }


  /**
   * Upload event handler
   */
  upload() {
    let files: Array<any> = new Array<any>();
    files.push(this.fileImage);
    this.files.emit(files)
  }
}
