import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common/constants/app.constants';
import { FetchBookResponseDTO } from 'src/app/common/model/fetchBook.dto';

/**
 * @class ShowRenewDataComponent
 * @author Saurabh bajpai
 */
@Component({
  selector: 'app-show-renew-data',
  templateUrl: './show-renew-data.component.html',
  styleUrls: ['./show-renew-data.component.scss']
})
/**
 * This class used to show the status of renewed items
 */
export class ShowRenewDataComponent implements OnInit {
  /**
   * Dialog is used to popup the component
   */
  dialog: LsMdDialog<ShowRenewDataComponent> | any
  /**
   * To store data
   */
  dataList
  /**
   * Represent the instance of AppConstant class
   */
  constants: AppConstants;
  /**
   * Array of type fetch book response dto to store renewed items details
   */
  tableArr: Array<FetchBookResponseDTO> = [];

  /**
   * @constructor function
   * @param router Router service will get injected into the component
   */
  constructor(
    private router : Router 
  ) { }
  /**
   * This method gets executed on the loading of the component
   */
  ngOnInit() {
    //console.log("Pop us response");
    this.tableArr = this.dataList.tableArr;
    //console.log("such mei", this.tableArr);
  }
  /***
   * This method gets executed on the click on close button of popup box
   * This method used to close the pop up component
   */
  closeKeyboard(){
    this.dialog.close();
  }

}
