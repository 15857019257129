
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



export class UploadModel {
  fileName: string
  userType: number
  userId: string
  url: string
  ext: string
  newlyAdded: boolean;   //FE
  // loadingComplete:boolean;   //FE
  name: string//FE
  size?: number //FE
  viewOnly?: boolean = false// to disable download in file submission component
}


@Injectable()
export class LsFileUploadService {

  constructor(private http: HttpClient) { }
  public upload(file: any, url: string, callback) {
    var header = new Headers();
    header.append('Content-Type', 'image/png');
    // this.http.post(url, formData).pipe(catchError(this.errorHandlers)).subscribe(data => {
    //   callback(data)
    // });


    let formData = new FormData();
    formData.append('FILE', file);
    let uploadModel = new UploadModel();
    uploadModel.fileName = (new Date().getTime()) + "";
    uploadModel.userId = 'LSNG';
    uploadModel.userType = 1;
    formData.append('DATA', JSON.stringify(uploadModel));
    this.http.post(url, formData).subscribe(data => {
      console.log(data);
      callback(data)
    })





  }
  private errorHandlers(error: Response) {
    return observableThrowError(error || 'Error Occured');
  }
}
