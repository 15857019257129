import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ButtonVariants } from '../button-params.enum';
/**
 * The wrapper component for the material's button component.
 */
@Component({
  selector: 'ls-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  /**
   * button variant type
   */
  @Input('variant') buttonVariant: string;
  /**
   * value of the button
   */
  @Input('value') buttonValue: string;
  /**
   * this value will enable/disable the button
   */
  @Input('disabled') disabled: boolean;
  /**
   * icon to be applied on the button
   */
  @Input('icon-class') iconClass: string;
  /**
   * color of the field
   */
  @Input('color') color: string;
  /**
   * button type
   */
  @Input('type') buttonType: string;
  /**
   * Event emitted when user click on the button
   */
  @Output('clicked') clickEvent = new EventEmitter<any>();
  /**
   * Button variants object
   */
  ButtonVariants = ButtonVariants;

  /**
   * The constructor
   */
  constructor() { }
  /**
   * @ignore
   */
  ngOnInit() {
  }
  /**
   * Emit an event on button click
   * @param event 
   */
  clicked(event: Event): void {
    this.clickEvent.emit(event);
  }

}
