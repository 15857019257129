import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '../constants/app.constants';

@Pipe({
  name: 'rfidItem'
})
export class RfidItemPipe implements PipeTransform {

  transform(value: string| number, ...args: any[]): any {
     return AppConstants.ITEM_TYPE.get(AppConstants.RFID_TO_ITEM_TYPE.get(+value)) 
  }

}
