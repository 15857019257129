<div [ngSwitch]="buttonVariant" class="ls-button">
  <button (click)="clicked($event)" class="narrow" [type]="buttonType" mat-button [disabled]="disabled" *ngSwitchCase="'flat'" color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
  <button (click)="clicked($event)" [type]="buttonType" mat-raised-button [disabled]="disabled" *ngSwitchCase="'raised'" color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
  <button (click)="clicked($event)" [type]="buttonType" mat-icon-button [disabled]="disabled" *ngSwitchCase="'icon'" color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
  <button (click)="clicked($event)" [type]="buttonType" mat-fab [disabled]="disabled" *ngSwitchCase="'fab'" color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
  <button (click)="clicked($event)" [type]="buttonType" mat-mini-fab [disabled]="disabled" *ngSwitchCase="'mini-fab'" color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
  <button (click)="clicked($event)" class="narrow" [type]="buttonType" mat-button [disabled]="disabled" *ngSwitchDefault color="{{color}}">{{buttonValue}} <ng-container *ngTemplateOutlet="content"></ng-container></button>
</div>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>