/**
 * Used to store patron information in self mode
 */
export class SelfPatron{
    patron_status:string;
    language:string;
    transaction_date: string;
    institution_id:string;
    patron_identifier: string;
    personal_name:string;
    valid_patron:string;
    valid_patron_pwd:string;
    currency_type:string;
    totalFineAmt:string;
    todayFine:string;
    //check this point
    screen_msgst:string;
    csn_no:string;
    Dob:string;
    Sex:string;
    email:string;
    phone_no:string;
   fingerRegistered: boolean;
    imgPath: string;
    Group: string;
    RegstnDate: string;
    Designation: string;
    AddlId: string;
   card_lost : boolean;

    alt_patron_id:string;// DI

    category: string;
    expiry_date: string;

    memCatMode: string;

    changeIdModeAllowed: string;
    outstandingCount: string;

    overDueFineAmnt: string;
    remark: string;
    totalEntitlements: string;
    barcode: boolean;
}