import { Component, OnInit } from '@angular/core';
/**
 * @class PageNotFoundComponent
 * @author Kartik
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})

/**
  *This is used to display a page not found Error page if any random url is typed
  */
export class PageNotFoundComponent implements OnInit {

  /**
   * @constructor function
   */
  constructor() { }
  /**
   * This function will gets executed on load of this component.
   */
  ngOnInit() {
  }

}
