<!-- barcode mode & Manual mode ifconditions(screen 1 "true") else screen 2(manual) enterid password-->
<div class="main_container"> 
    
    <form [formGroup] ="barcodeForm" >
        <div class="header">
            <div class="header_information">WELCOME,</div>
            <div class="header_information2">Fill your Patron Details to Proceed</div>
        </div>
        <div class="body" *ngIf="this.initialResponseData.patronPasswordEnable" > 
            <div class="textbox">
             <input id="input1" class="input" required = true  formControlName="userId" placeholder="User ID" tabindex="1"  maxlength="16"  [(value)]="userValue" />
            </div>
            <div class ="textbox">
                <input id="input2" class="input" required = true type="password"  formControlName="password" placeholder="Pasword" tabindex="2" [(value)]="pwdValue" />
           
            </div>
            
        </div> 

        <div class="body" *ngIf="!this.initialResponseData.patronPasswordEnable" >
            <div class="textbox1">
                <input id="input1" class="input"  [required]=true formControlName="userId" placeholder="User ID" tabindex="1"  maxlength="16" [(value)]="keyboard?.input.input1" /> 
            </div>
        </div>
 
        

        <div class="btn">
            <div class="btn_click" *ngIf="this.initialResponseData.patronPasswordEnable" >
                    <ls-button [type] ="'submit'" [value]="'Continue'" (click)="patronLogin($event)"  ></ls-button>
            </div>
            <div class="btn_click" *ngIf="!this.initialResponseData.patronPasswordEnable" >
                    <ls-button [type] ="'submit'" [value]="'Continue'" (click)="patronLogin1($event)"  ></ls-button>
            </div>

            <div class="keyboardPos"> 
                    <ls-button (click)="keyboardClick($event);">
                            <img src="assets/svgs/keyboard.svg" width='34px' height='34px'>
                    </ls-button>
                    
            </div> 
            <p *ngIf="errorMsg" fxLayoutAlign="center center" class="message">{{errorMsg}}</p>
        </div>
        
    </form>
    <!-- {{keyboard?.input | json}} -->
    <div [hidden]="!openKeyboard"  style="text-align: center;" >
        <!-- <input (input)="onInputChange($event)" class="input" value={{value}} > -->
        <div  class="simple-keyboard"></div>
    </div> 
</div>
 