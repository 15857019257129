import { Component, OnInit } from '@angular/core';
/**
 * unused
 */
@Component({
  selector: 'app-otpkeyboard',
  templateUrl: './otpkeyboard.component.html',
  styleUrls: ['./otpkeyboard.component.scss']
})
/**
 * Unused
 */
export class OtpkeyboardComponent implements OnInit {
  /**
   * Unused
   */
  constructor() { }
  /**
   * Unused
   */
  ngOnInit() {
  }

}
