<!-- <p>over-due-document-dialog works!</p> -->
<div class="outer-container">
    <div class="heading-color"></div>
    <div class="body-section">
        <div class="overdue-document ">
            {{overdueInfo['screen_msg']}}
        </div>
        <div class="data">
            <div class="body-name">
                <div class="label f16 regular">
                    {{overdueInfo['personal_name']}}
                </div>
                <div class="label-value f16 regular">
                </div>
            </div>
            <div class="body-content">
                <div class="label f16 regular">
                    id :
                </div>
                <div class="label-value f16 regular">
                    {{overdueInfo['patron_identifier']}}
                </div>
            </div>
            <div class="body-content">
                <div class="label f16 regular">
                    Category :
                </div>
                <div class="label-value f16 regular">
                    {{overdueInfo.category}}
                </div>
            </div>
            <div class="body-content">
                <div class="label f16 regular">
                    Fine :
                </div>
                <div class="label-value f16 regular">
                    {{overdueInfo.totalFineAmt}}
                </div>
            </div>
            <div class="body-content">
                <div class="label f16 regular">
                    Card Expiry :
                </div>
                <div class="label-value f16 regular">
                    {{overdueInfo['expiry_date']}}
                </div>
            </div>
        </div>
        <div class="btn-container">
            <div class="btn-div">
                <ls-button (click)='proceed(true)'>
                    <span class="btnName">Proceed</span>
                </ls-button>
            </div>
            <div class="btn-div">
                <ls-button (click)='cancel()'>
                    <span class="btnName">Cancel</span>
                </ls-button>
            </div>

        </div>
    </div>

</div>