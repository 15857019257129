import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ButtonVariants } from 'src/LSNG/components/ls-button/button-params.enum';
import { AppConstants } from 'src/app/common/constants/app.constants';
import { LoginService } from 'src/app/common/service/login.service';
import { AppState } from 'src/app/common/service/app-state';
import { AppStateService } from 'src/app/common/service/appState.service';
import { LsDialogService } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { OkPopUpComponent } from 'src/app/common/components/ok-pop-up/ok-pop-up.component';
import { Router } from '@angular/router';
import * as shajs from 'sha.js';

/**
 * @class LoginComponent
 * @author saurabh bajpai
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
/**
 * This component used for login to Lsmart using thier credentials
 */
export class LoginComponent { 
/**
 * Local instance of global app state
 */
  appState: AppState = new AppState();
/**
 * Form group used for userType , userId and password
 */  
  loginForm: UntypedFormGroup;
/**
 * optionalList array used to store userType , self or admin
 */

  optionList: Array<{ value, label, visible, disabled }> = [{
    value: AppConstants.userType.self,
    label: "Self",
    visible: true,
    disabled: false
  }, 
  {
    value: AppConstants.userType.admin,
    label: "Admin",
    visible: true,
    disabled: false
  }];
  /**
   * Button variant type
   */
  variant: ButtonVariants = ButtonVariants.default; 
  /**
   * This is used for local error handling to display related errors
   */
  errorMessage: string = '';

  /**
   * To set userType
   */
  
  userType: number = 0;
  
  /**
   * @constructor function
   * @param fb FormBuilder service will be get injected into this component
   * @param loginService LoginService will be get injected into this component
   * @param appStateService AppStateService will be get injected into this component
   * @param popupService PopUpService will get injected into this component
   */

  constructor(
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private appStateService: AppStateService,
    public popupService: LsDialogService<OkPopUpComponent>,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      radio: [''],
      userId: ['', [Validators.required, Validators.maxLength(16)]],
      password: ['', [Validators.required]],
    });
  }
/**
 * This function will gets executed on load of this component
 * User type will be selected on the calling of setDefaultUserType
 * now we subscribe appStateModel
 * checked any initial response error
 *  
 */ 
public doUnload(): void{
 this.doBeforeUnload();     
}
  ngOnInit() {
    
    if(this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.loginResponse)){
      this.router.navigate(['/dashboard']);
      
    }
  
    // AppConstants.localStorageItems.checkUserType = this.userType;
    this.setDefaultUserType();
    this.appStateService.appStateModel.subscribe(ele=>{
      //console.log(ele.isInitialRespErr);
      
    })
  }
public doBeforeUnload(): void {
  localStorage.clear();
}
  /**
   * On click on login button we will send a object named body which contains userID , password, fromKiosk
   * password is SHA1 encrypted 
   * @param event click event
   */

  loginIntoSystem(event) {
    let errData = {
      errMsg: 'RFID not working',
      errType: 1 
    };

  // this.popupService.open(OkPopUpComponent, {}, errData).subscribe((resp) => {});
    if (this.loginForm.controls['userId'].valid && this.loginForm.controls['password'].valid) {
      //console.log('valid form');
      let body;
      let strongPassword = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings).strongPassword;
      let password;
      if (strongPassword) {
        password = this.loginForm.controls['password'].value;
      }
      else {
        password = this.loginForm.controls['password'].value.toUpperCase();
      }
      let sha1Password = shajs('sha1').update(password).digest('hex');
      if (this.userType == AppConstants.userType.self) {
        body = {
          'userId': this.loginForm.controls['userId'].value,
          'password': sha1Password,
          'fromKiosk': true
        }
      } else {
        body = {
          'userId': this.loginForm.controls['userId'].value,
          'password': sha1Password,
          'fromKiosk': false
        }
      }
      this.loginService.login(body, (data) => {
        // console.log(data);
        //After checking successful login
        if (data && data.flag == 1) {
          this.loginService.actionsAfterLoginSuccess(data.data, this.userType,this.loginForm.controls['userId'].value);
        }
        else if (data && data.flag == 0 && data.message) {
          this.errorMessage = data.message;
        }
      });

    }
    else {
      if(this.loginForm.value.userId == "" && this.loginForm.value.password == ""){
        this.errorMessage = "Please Enter Login Credentials"
      }
      else if(this.loginForm.value.userId == ""){
        this.errorMessage = "Please Enter User Id"
      }else if(this.loginForm.value.password == ""){
        this.errorMessage = "Please Enter Password"
      }
    }
  }
  /**
   * This method is used for clear the fields named userId and password
   */

  clearFields() {
    this.loginForm.patchValue({
      userId: '',
      password: ''
    })
  }
  /**
   * This method used for select default userType
   */

  setDefaultUserType() {
    if (AppConstants.global.initialRequestData && AppConstants.global.initialRequestData.operationMode != null && AppConstants.global.initialRequestData.operationMode != undefined) {
      this.loginForm.patchValue({ radio: AppConstants.global.initialRequestData.operationMode });
      this.userType = AppConstants.global.initialRequestData.operationMode;
    }
  }

  // actionsAfterLoginSuccess(resp: LoginResponseDTO) {
  //   //updated login response in local-storage
  //   this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.loginResponse, resp, true);
  //   //save userType in localStorage
  //   this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.userType, this.userType);
  //   //updated login response and user-type in app-state
  //   this.appState.loginResponse = resp;
  //   this.appState.isLoggedIn = true;
  //   this.appState.userType = this.userType;
  //   this.appStateService.update(this.appState);
  //   //navigate to dashboard
  //   this.router.navigate([AppConstants.delimiters.urlDelimiter + AppConstants.routes.dashboard]);
  // }
/**
 * This method used to update the value of userType on the bases of click event 
 * @param event click event
 */
  userTypeUpdated(event) {
    this.userType = event.value;
    this.appStateService.setItemToLocalStorage( AppConstants.localStorageItems.userType, this.userType);
  }

  // onBlurMethodForUserId() {
  //   if (!this.loginForm.controls['userId'].valid) {
  //     if (!this.loginForm.controls['userId'].value.length) {
  //       this.userHintLabel = "User Id cannot be empty"
  //     }
  //     else if (this.loginForm.controls['userId'].value.length > 16) {
  //       this.userHintLabel = "User Id cannot be more than 16 characters"
  //     }
  //   }
  // }

  // onBlurMethodForPassword() {
  //   if (!this.loginForm.controls['password'].valid) {
  //     if (!this.loginForm.controls['password'].value.length) {
  //       this.passwordHintLabel = "Password can not be empty"
  //     }
  //   }
  // }

}
