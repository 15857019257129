import { Enum } from 'typescript-string-enums';
/**
 * Used to store patron status response
 */
export class PatronStatusResponse {
     patron_status: string;
     language: string;
     transaction_date: string;
     institution_id: string;
     patron_identifier: string;
     personal_name: string;
     valid_patron: string;
     valid_patron_pwd: string;
     currency_type: string;
     totalFineAmt: string;
     todayFine: string;
     screen_msg: string;
     csn_no: string;
     Dob: string;
     Sex: string;
     email: string;
     phone_no: string;
     fingerRegistered : boolean;
     imgPath: string;
     Group: string;
     RegstnDate: string;
     Designation: string;
     AddlId: string;
     card_lost : boolean;
     alt_patron_id: string;// DI
     category: string;
     expiry_date: string;
     memCatMode: string;
     changeIdModeAllowed: string;
     outstandingCount: string;
     overDueFineAmnt: string;
     remark: string;
     totalEntitlements: string;
     memberId
     libsysCode

}

export const OperationsEnum = Enum({
        "CHECK_OUT" :   "0",
        "CHECK_IN" : "1",
        "RENEW" : "2",
        "FINE_PAYMENT" : "3",
        "RETAG" :"4",
        "UNTAG" :"5",
        "TAGGING" :"6",
        "VERIFY_ITEM" :"7",
        "TAGGING_ENQUIRY" :"8",
});


export type OperationsEnum = Enum<typeof OperationsEnum>;