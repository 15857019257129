<div [ngClass]="{'mainRouterContainer': isChildPresent}">
    <div *ngIf="isChildPresent" class="header-pnl" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutGap="-5px">
            <div class="heading">
                <p>{{displayHeading}}</p> 
            </div>
            <div class="triangle"></div> 
        </div>
        <div *ngIf="checkUserType == 0"> 
        <a (click)="routeToDashboard()">
            <div class="dashboardIcon" fxLayout="row">
                <img src='assets/svgs/dashboard.svg' class="dashboardIconImage">
                <div class="dashboard">DashBoard</div>
            </div>
        </a>
    </div>
    </div>
    <div id="router">
        <router-outlet></router-outlet> 
    </div>
</div>
 