import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { AppStateService } from './appState.service';
import { Observable, throwError } from 'rxjs';
import { AppConstants } from '../constants/app.constants';
import { LoaderService } from "../service/loader.service";
import { tap, catchError } from "rxjs/operators";
import { LoginService } from './login.service';
import { Router } from '@angular/router';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private appStateService: AppStateService,
        private loader: LoaderService,
        private loginService: LoginService,
        private router: Router
    ) { }

    private token: string = '';
    private authReq;
    private timer;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authReq = req.clone({});
        if (isPlatformBrowser(this.platformId)) {
            if (req.headers.get("no-auth")) {
            } else {
                this.authReq = req.clone({
                    headers: req.headers.set('Authorization', '')
                });
                if (this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.token))
                    this.token = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.token);
                this.authReq = req.clone({
                    headers: req.headers.set('Authorization', this.token)
                });
            }
        }
        // this.loader.increment();
        return next.handle(this.authReq).pipe(tap(evt => {
            if (evt instanceof HttpResponse) {
                // let loginUrl = '/login'
                // console.log(evt.status);

                // if(evt.status == 200){
                //     // this.loginService.adminExitActions();
                //         localStorage.clear();
                //     clearTimeout(this.timer)
                //     this.timer = setTimeout(() => {
                //         this.router.navigate([loginUrl]);
                //         alert("\n Your session has expired ! ! ! ");
                //         document.getElementsByClassName('cdk-overlay-container')[0].classList.add('hide');
                //       }, 500)
                // } 
            }

        }, (err) => {
            if (err instanceof HttpErrorResponse) {
                // this.loader.decrement();
                console.log(err.status);
                
                if (err.status == 401) {
                    let loginUrl = '/login'
                    this.loginService.adminExitActions();
                    // setTimeout(() => {
                        // localStorage.clear()
                    // }, 400);
                    // clearTimeout(this.timer)
                    // this.timer = setTimeout(() => {
                        alert("\n Your session has expired ! ! ! ");
                        this.router.navigate([loginUrl])
                        document.getElementsByClassName('cdk-overlay-container')[0].classList.add('hide');
                        console.log(document.getElementsByClassName('cdk-overlay-container')[0].classList);

                    // }, 700)
                    return;
                }
            }
        }),
            // catchError(err=>{
            //     // this.loader.decrement();
            // return throwError(err)
            // })
        )
        // return next.handle(this.authReq).pipe(timeout(AppConstants.global.time_out)).do(event => {
        // }, err => {

        // });
    }
}
