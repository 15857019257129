import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/common/service/http.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
// import { request } from 'http';


@Injectable({
  providedIn: 'root'
})
export class BiometricService {


 public verificationDataObs=new Subject<boolean>();
/**
 * 
 * @param _http HttpClient wiil be injected into the component
 * @param httpService HttpService wiil be injected into the component
 */
  constructor(
    private _http: HttpClient,
    private httpService: HttpService
  ) { }
  
  /**
   * To get patron information by useing biometric
   * @param patronId patron Id 
   * @param callback function
   */
  biometricPatronInfo(patronId,callback: (data:any) => void) {
    let Url = environment.service_url+"/api/biometric/patronDetails";
    let requestParams = new Map().set('patronId', patronId);
      this.httpService.get(Url, requestParams, (resp) => {
        callback(resp);
      }); 
    }
  /**
   * To register the patron
   * @param body body
   * @param patronId patron id
   * @param callback function
   */
  biometricRegistration(body , patronId, callback: (data)=>void){
    let url = environment.service_url + '/api/biometric/register';
    let requestParams = new Map().set('patronId', patronId);
    this.httpService.post(url , body, requestParams, (resp) =>{
      callback(resp);
    });
  }
  /**
   * To verify the patron
   * @param body body
   * @param callback funtion
   */
  biometricVerification(body, callback: (data)=>void){
    let url = environment.service_url + '/api/biometric/verify';
    let requestParams = new Map();
    this.httpService.post(url , body , requestParams , (resp)=>{
      callback(resp);
    }) 
  }
  /**
   * To remove the patron
   * @param body body 
   * @param patronId Patron id
   * @param callback function
   */
  biometricRemove(body , patronId , callback:(data)=>void){
    let url = environment.service_url + '/api/biometric/remove';
    let requestParams = new Map().set('patronId', patronId); 
    this.httpService.post(url, body, requestParams , (resp) =>{
      callback(resp);
    }) 
  }
}
