<div class="login-box">
    <form [formGroup]="loginForm" (ngSubmit)="loginIntoSystem($event)">
        <div class="login-header">
            <p class="lg-bold-font">LOGIN<p>
                    <div class="header-btn">
                        <ls-radio [radio-options]="optionList" formControlName="radio" (changeRadio)="userTypeUpdated($event)"></ls-radio>
                    </div>
        </div>
        <div class="login-page">
            <div class="textbox">
                <i class="fa fa-user" aria-hidden="true"></i>
                <ls-text-field [required]=true formControlName="userId" placeholder="User ID"  maxlength="16"></ls-text-field>
            </div>
            <div class="textbox">
                <i class="fa fa-lock" aria-hidden="true"></i>
                <ls-text-field type="password" [required]=true formControlName="password" placeholder="Password"></ls-text-field>
            </div>
        </div> 
        <div class="btn">
            <div class="btn_click1">
                <ls-button [variant]="variant" [type]="'reset'" [value]="'Cancel'" (click)="clearFields()"></ls-button>
            </div>
            <div class="btn_click">
                <ls-button [type] ="'submit'" [variant]="variant" [value]="'Login'" ></ls-button>
            </div>
        </div>
        <div class="message" *ngIf="errorMessage && errorMessage.length">{{errorMessage}}
            
        </div>
    </form>
</div>