<td-paging-bar #pagingBarLinks [pageLinkCount]="linkCount" [firstLast]="showFirstLast" [pageSize]="pageSize" [total]="total"
  [initialPage]="initialPage" (change)="pageChange($event)" #pagingBar>
  <ng-container *ngIf="showRange">{{pagingBar.range}} of {{pagingBar.total}}</ng-container>
  <ng-container *ngIf="showGoTo">
    <p>Go to:</p>
    <mat-form-field [style.width.px]="30">
      <input #goToInput matInput type="number" [min]="1" [max]="pagingBarLinks.maxPage" [value]="pagingBarLinks.page"
        (blur)="goToInput.value = pagingBarLinks.page" (keyup.enter)="pagingBarLinks.navigateToPage(goToInput.value); goToInput.value = pagingBarLinks.page" />
      {{pagingBar.range}} of {{pagingBar.total}}
    </mat-form-field>
  </ng-container>
</td-paging-bar>