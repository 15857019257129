import { Injectable } from '@angular/core'; import { Observable, BehaviorSubject } from 'rxjs';
/**
 * Loader State Model class
 * @class LoaderStateModel
 */
export class LoaderStateModel {
    show: boolean;
    count:number=0;
    constructor(show,count){}
}
/**
 * This loader service used to show loader while until we get response from server
 */
@Injectable()
/**
 * This class used to show loader
 */
export class LoaderService {  
    /**
     * Instance of loaderStateModel
     */
    loaderStateStore: LoaderStateModel= new LoaderStateModel(false,0);
    /**
     * Observable of LoaderStateModel
     */
    loaderState: Observable<LoaderStateModel>;
    /**
     * Behaviour subject of laoderState 
     */
    private loaderStateBehaviourSubject: BehaviorSubject<LoaderStateModel>;
    /**
     * @constructor function
     */
    constructor() {
        this.loaderStateBehaviourSubject = <BehaviorSubject<LoaderStateModel>>new BehaviorSubject(this.loaderStateStore);
        this.loaderState = this.loaderStateBehaviourSubject.asObservable();
    }
    /**
     * To show loader
     */
    show() {
        this.loaderStateStore.show=true;
        this.loaderStateBehaviourSubject.next(Object.assign({},this.loaderStateStore));
    }
    /**
     * To hide loader
     */
    hide() {
        this.loaderStateStore.show=false;
        this.loaderStateBehaviourSubject.next(Object.assign({},this.loaderStateStore));   
     }
    /**
     * Increment loader state store count
     */ 
    increment(){
        this.loaderStateStore.count+=1;
        this.loaderStateBehaviourSubject.next( Object.assign({},this.loaderStateStore));
    }
    /**
     * Decrement loader state store count
     */
    decrement(){
        this.loaderStateStore.count-=1;
        this.loaderStateBehaviourSubject.next( Object.assign({},this.loaderStateStore));
    }
    /**
     * To get the value of loaderStateBehaviourSubject
     */
    getState() {
        return this.loaderStateBehaviourSubject.getValue();
    }

}

