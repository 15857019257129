import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import {Operations,OperationsEnumMap } from '../model/operations.enum'
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @class TransactionService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class TransactionService {


  routeBS :BehaviorSubject<any>;
  routeObs : Observable<any>;
/**
 * 
 * @param http HttpService wiil be injected into the component
 */
  constructor(
    private http:HttpService,
  ) { 
    this.routeBS = new BehaviorSubject(undefined);
    this.routeObs = this.routeBS.asObservable();
  }
  /**
   * To discard transaction slip service
   * @param callback function
   */

  updateRoute(any){
    this.routeBS.next(any);
   }
   
  discardTransactionSlip(callback: (data)=>void){
    let url = environment.service_url + '/api/circulation/discard/slip';
    let requestParams = new Map();
    this.http.get(url,requestParams,(resp)=>{
      callback(resp);
    })
  }
  /**
   * To transaction slip service 
   * @param type type of transaction
   * @param callback function
   */
  transactionSlip(type:number, callback: (data) => void){
    let url=environment.service_url + `/api/circulation/${OperationsEnumMap.get(type)}/slip`
    let requestParams = new Map();
    // requestParams.set('checkout', type);
    this.http.get(url,requestParams,(resp)=>{
      //console.log("transaction service response", resp)
      callback(resp);
    })
  }
}
