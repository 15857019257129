import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { StartupService } from '../../service/startup.service';
/**
 * @class OkPopUpComponent
 * @author Jatin
 */
@Component({
  selector: 'app-ok-pop-up',
  templateUrl: './ok-pop-up.component.html',
  styleUrls: ['./ok-pop-up.component.scss']
})
export class OkPopUpComponent implements OnInit {

  /** It is used to insert values in mainText and subText  */
  dataList;

  /** It is used to open a Dialog box */
  dialog: LsMdDialog<OkPopUpComponent>;

  /** It is used to set the path of error Image */
  errorImg="assets/svgs/unsuccessful_transactions.svg";

  /** It is used to set the path of Informative Image */
  infoImg="assets/svgs/eror_in_transaction.svg";

  /** It is used to set error text */
  error ="Error";

  /** It is used to set the informative message */
  info="Something went wrong";
  

  constructor(  ) {   }

  ngOnInit() {
  }
 
  /** It is used to close the dialog box */
  close(msg?){
    let data = msg;
    this.dialog.close(data);
  }
 
}
