import { Component, OnInit, Input } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
/**
 * @class RfidErrorHandlerComponent
 */
@Component({
  selector: 'app-rfid-error-handler',
  templateUrl: './rfid-error-handler.component.html',
  styleUrls: ['./rfid-error-handler.component.scss']
})
/**
 * This component used to show rfid error messages
 */
export class RfidErrorHandlerComponent implements OnInit {
  /**
   * Used to store responseCodeError
   */
  msgDetails: any;
  /**
   * Either to red col;or or not
   */
  redColor:boolean;
  /**
   * To store msg details
   */
  msg: string;
  /**
   * This method is responsible for getting rfid error code with their respective messages
   */
  @Input() set errorCode(code) {
    let arr
    if(code && ( arr =code.match("#[0-9]{3}"))){
      // = code.match("#[0-9]{3}");

      let erCode = arr[0];
      this.msgDetails = AppConstants.responsCodeErrorMap.get(erCode);
      if(this.msgDetails){
        this.msg=this.msgDetails.message;
        this.color(this.msgDetails.color)
      }
    }else{
      this.msg= null;
    }
  }
  /**
   * decides color type either red or not
   * @param clr 
   */
  color(clr) {
    
    if(clr){

      if(clr.toLowerCase()=="red")
      {
           this.redColor=true;
      }
      else
      {
           this.redColor=false;
      }
    
    }
  }
  /**
   * @constructor function
   */
  constructor() { }
  /**
   * gets executed on the load of the component
   */
  ngOnInit() {
    
    
  }

}
