import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { LsFileUploadService } from '../ls-file-upload/ls-file-upload.service';
import { MatButtonModule } from '@angular/material/button';
import { LsDropFileDirective} from '../../directives/ls-drop-file/ls-drop-file.directive';
import { LsImageUploadComponent } from './ls-image-upload/ls-image-upload.component';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  imports: [
    CommonModule,MatButtonModule,HttpClientModule
  ],
  declarations: [FileUploadComponent,LsDropFileDirective, LsImageUploadComponent],
  exports:[FileUploadComponent,LsDropFileDirective,LsImageUploadComponent],
  providers: [LsFileUploadService]
 
})
export class LsFileUploadModule { }
