import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from "../../../environments/environment";
import { LoginResponseDTO } from '../model/login.response';
import { AppState } from './app-state';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';
import { Router } from '@angular/router';
import { InitialResponseDTO } from '../model/initial-response.dto';
import { ItemInfoRFIDService } from './itemInfoRFID.service';

/**
 * @class LoginService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  appState: AppState = new AppState();
  getInitialLoopBK14
  /**
 * 
 * @param httpService HttpService wiil be injected into the component
 * @param appStateService AppStateService wiil be injected into the component
 * @param router Router service wiil be injected into the component
 */
  constructor(
    private httpService: HttpService,
    private appStateService: AppStateService,
    private router: Router,
    private itemInfo : ItemInfoRFIDService
  ) { 
    this.appStateService.appStateModel.subscribe((appState: AppState) => {
      this.appState = appState;
    });

    // this.itemInfo.showDialogObs.subscribe(data => {
    //   console.log(data);
    //   this.getInitialLoop = data;
    // })
    console.log(this.getInitialLoopBK14);
    
  }
/**
 * This service used to verify the user
 * @param requestDTO Request dto
 * @param callback call function
 */
  login(requestDTO, callback: (data) => void) { 
    let url = environment.service_url + "/logIn";
    let requestParams = new Map();
    // requestParams.set('rememberMe', rememberMe);
    this.httpService.post(url, requestDTO, requestParams, (resp) => {

      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }
/**
 * set the response in local storage and update the appstate service according to the response
 * @param resp loginResponse Dto
 * @param userType usertype
 */
  actionsAfterLoginSuccess(resp: LoginResponseDTO, userType,userId) {
    //updated login response in local-storage
    this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.loginResponse, resp, true);
    //save userType in localStorage
    this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.userType, userType);
    this.appStateService.setItemToLocalStorage(AppConstants.localStorageItems.userId,userId);
    //updated login response and user-type in app-state
    this.appState.loginResponse = resp;
    this.appState.isLoggedIn = true;
    this.appState.userType = userType;
    this.appState.displayUserId = userId;
    this.appStateService.update(this.appState);
    this.navigateToDashboard();
    //navigate to dashboard, if self mode with card else navigate to self-without-card
    // if (userType == AppConstants.userType.self) {
    //   let initialResp: InitialResponseDTO = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings);
    //   if (initialResp.selfMemberIdentificationMode == AppConstants.selfIdentificationModes.barcode) {
    //     this.navigateToSelf();
    //   } else {
    //     this.navigateToDashboard();
    //   }
    // } else {
    //   this.navigateToDashboard();
    // }
  }
/**
 * navigate to serlf
 */
  navigateToSelf() {
    this.router.navigate([AppConstants.delimiters.urlDelimiter + AppConstants.routes.self]);
  }
/**
 * navigate to dashboard
 */
  navigateToDashboard() { 
    this.router.navigate([AppConstants.delimiters.urlDelimiter + AppConstants.routes.dashboard]);
  }
/**
 * when admit exit
 */
  adminExitActions() {
    if(this.getInitialLoopBK14){
      clearInterval(this.getInitialLoopBK14);
    }
    //remove login-response, user-type and token from local-storage
    this.appStateService.removeItemFromLocalStorage(AppConstants.localStorageItems.loginResponse);
    this.appStateService.removeItemFromLocalStorage(AppConstants.localStorageItems.userType);
    this.appStateService.removeItemFromLocalStorage(AppConstants.localStorageItems.token);
    this.appStateService.removeItemFromLocalStorage(AppConstants.localStorageItems.userId);
    this.appStateService.removeItemFromLocalStorage(AppConstants.localStorageItems.NextRfidReader);
    //update app-state
    localStorage.removeItem('nextRfidReader');
    this.appState.displayUserId = '';
    this.appState.isLoggedIn = false;
    this.appStateService.update(this.appState);
    // localStorage.removeItem('userId');
    // window.location.reload();
  }

}
