<div class="footerContainer">
    <div class="main">
        <div class="leftDiv">
            <p>LIBSYS Ltd. All rights reserved.</p>
        </div>
        <div class="midDiv">
            <p>Licence to - {{constants?.global?.initialRequestData?.licenseName}}</p>
        </div>
        <div class="rightDiv">
            <div class="date-div">
                <!-- <p>{{currentTime | date : ' dd-MMM-yyyy &nbsp; hh : mm : ss'}} </p> -->
                <p>{{currentTime | date : ' dd-MMM-yyyy'}} </p>
            </div>
            <div class="time-div">
                <p>{{currentTime | date : ' hh:mm:ss a'}} </p> 
            </div>
        </div>
    </div>
</div>