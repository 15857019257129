<div class = "mainContainer">
    <div class="header" fxLayoutAlign="center center">
        Are You Sure Want To Renew Selected Items
    </div>
    <div class="footer-div" fxLayout="row" fxLayoutAlign="center center">
        <div class="left-btn">
            <ls-button (click)="cancelButton();"> 
                <span class="btn-text  md-medium-font">No</span>
            </ls-button>
        </div>
        <div class="right-btn">
            <ls-button (click)="RenewSelectedItems();">
                <span class="btn-text  md-medium-font">Yes</span>
            </ls-button>
        </div>
    </div>
</div>