import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PatronStatusRequestModel } from '../model/patronStatusRequest.model';
import { SelfPatronResponseDTO } from '../model/selfPatronResponse.dto';
import { AppConstants } from '../constants/app.constants';
import { InitialResponseDTO } from '../model/initial-response.dto';
import { AppStateService } from './appState.service';

/**
 * @class SelfModePatronInfoWithoutCardService
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class SelfModePatronInfoWithoutCardService {
  /**
   * 
   * @param httpService HttpService wiil be injected into the component
   * @param router Router service wiil be injected into the component
   * @param appStateService AppStateService wiil be injected into the component
   */
  constructor(
    private httpService: HttpService,
    private router: Router,
    private appStateService: AppStateService,
  ) { }
/**
 * To get the patron Info
 * @param requestDTO 
 * @param callback 
 */
  patronVerification(requestDTO, callback: (data) => void) {
    let url = environment.service_url + '/api/patron/info'
    let requestParams = new Map();
    this.httpService.post(url, requestDTO, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }

}
