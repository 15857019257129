<div class="bgdiv mainRouterContainer">

    <div class="header-pnl" fxLayout="row-reverse" fxLayoutAlign="space-between">
        <a [routerLink]="'/dashboard'">
            <div class="dashboardIcon" fxLayout="row">
                <img src='assets/svgs/dashboard.svg' class="dashboardIconImage">
                <div class="dashboard">DashBoard</div>
            </div>
        </a>
    </div>
    <div id="router">
        <div class="error-div">
            <div class="error-msg">
                <p class="xlg-medium-font error-text">Page Not Found!</p>
            </div>
        </div>
    </div>

</div>