import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoaderService, LoaderStateModel } from '../../service/loader.service';
/**
 * @class LoaderComponent
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
/**
 * This component shows us Laoder
 */
export class LoaderComponent implements OnInit {
  /**
   * Decides either show the loader or not
   */
  show: boolean;
  // loaderPath: string = "assets/Icons/Indeterminate-Loader.gif";
  /**
   * To store the loaderState of loader Service
   */
  private subscription;
  /**
   * 
   * @param loaderService LoaderService will be injected into the component
   * @param cdr ChangeDetector provide change detection functionality 
   */
  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }
  /**
   * This method gets executed on the load of this component
   */
  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderStateModel) => {
      this.show = (state.show || state.count >0);
      this.cdr.detectChanges();
    });
  }
  /**
   * This method gets executed when we leave this component
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
