import { Component, OnChanges, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NG_VALIDATORS } from '@angular/forms';

/**
 * This component is wrapper to mat-slide-toggle.
 */
@Component({
  selector: 'ls-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true
  }]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {

  /**
   * color of toggle
   */
  @Input('color') color: string;
  /**
   * disable the toggle or not
   */
  @Input('disabled') disabled: boolean;
  /**
   * make toggle value true by default
   */
  @Input('checked') checked: boolean;
  /**
   * event for value change of toggle
   */
  @Output('checkedChange') checkedChange = new EventEmitter<boolean>();

  /**
   * @constructor
   */
  constructor() { }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * change event
   * @param e - event
   */
  onChange(e) {
    this.propagateChange(e.checked);
    this.checkedChange.emit(e.checked);
  }

  /**
   * propagate change
   */
  propagateChange = (_: any) => { };

  /**
   * registers callback function value changes
   * @param fn 
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched() { }

  /**
   * writes new value to control
   * @param value 
   */
  writeValue(value: any) {
    if (value !== undefined)
      this.checked = value;
  }

}
