import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { AppConstants } from 'src/app/common/constants/app.constants';
import { filter } from 'rxjs/operators';
import { AppStateService } from 'src/app/common/service/appState.service';
import { AppState } from 'src/app/common/service/app-state';
import { BackDropPopUpComponent } from 'src/app/common/components/back-drop-pop-up/back-drop-pop-up.component';
import { LsDialogService, LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { TransactionService } from 'src/app/common/service/transaction.service';
import { RFIDLayerService } from 'src/app/common/service/rfidLayer.service';
import { ItemInfoRFIDService } from 'src/app/common/service/itemInfoRFID.service';
import { LoginService } from 'src/app/common/service/login.service';

/**
 * @class HomePageComponent
 * @author Saurabh bajpai
 */
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
/**
 * This component shows us a common screen for all the components, and their handlings
 */
export class HomePageComponent implements OnInit, OnDestroy {
  /**
   * Decides that weather child present or not
   */
  isChildPresent: boolean;
  /**
   * Subscribe the router event
   */
  routerSubs;
  /**
   * To display the heading
   */
  displayHeading: string;
  /**
   * Subscribe appState model of appState class
   */
  appStateSubscription;
  /**
   * Decides weather user follow the renew flow or checkout , checkin
   */
  fromRenew: boolean;
  /**
   * Instance of global class AppState
   */
  appstate: AppState;
  /**
   * Store the value as shown below
   */
  checkUserType: number;
  isBackDropPopUpOpen: boolean = false;
  initialLoop: any;

  backDropContentArr: Array<any> = [];
  isDropExist: boolean = false;
  closeBackDropPopUp: LsMdDialog<BackDropPopUpComponent>;
  isDialogBoxOpen: boolean = true;
  /**
   * @constructor function
   * @param router Router service will be injected
   * @param appStateService AppStateService will be injected into the component
   */
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private backDropComponent: LsDialogService<BackDropPopUpComponent>,
    private transactionService: TransactionService,
    private rfidLayerService: RFIDLayerService,
    private itemInfo: ItemInfoRFIDService,
    private loginService : LoginService
  ) {
    this.appStateService.appStateModel.subscribe((state) => {
      this.appstate = state;      
    });
  }
  /**
   * This method will gets executed on the load of this component
   */
  ngOnInit() {
    let otherReader = localStorage.getItem('nextRfidReader') == 'true' ? true : false;
    console.log(otherReader);

    this.checkUserType = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userType)
    this.getRoute();
    this.appStateSubscription = this.appStateService.appStateModel.subscribe((state) => {
      let appState: AppState = state;      
      if (appState.fromRenew) {
        this.fromRenew = true;
      } else {
        this.fromRenew = false;
      }
    })

    if (otherReader && this.appstate && this.appstate.isLoggedIn) {
      // this.showBackDropPopUp();
      this.loginService.getInitialLoopBK14 = setInterval(() => {
        this.showBackDropPopUp();
      }, 2000);
    }
  }

  showBackDropPopUp() {
    this.rfidLayerService.rfidLayerForBackDrop(resp => {
      this.itemInfo.openDialogBackDrp(this.initialLoop);
      if (resp) {
        let alpha = /^[A-Za-z0-9]+$/
        let firstChar = resp.substring(0, 1);
        let backDropTags = resp.slice(5);
        if (resp.startsWith('#') || backDropTags.startsWith('#')) {
          console.log('failed');
        } else {
          this.backDropCheckInPerform(backDropTags);
        }
      }
    })
  }

  backDropCheckInPerform(body) {
    this.itemInfo.getItemInforBackDropcheckIn(body, resp => {
      if (resp && resp['data'] && resp['data'].circulationResponse) {
        this.itemInfo.updateBackDropData(resp['data'].circulationResponse);

        // if (!this.isDropExist && this.backDropContentArr && this.backDropContentArr.length) {
        if (this.isDialogBoxOpen) {
          this.isDialogBoxOpen = false;
          this.backDropComponent.open(BackDropPopUpComponent, { panelClass: "back-drop-wrapper" }, {}).subscribe(data => {
            if (data && data.fromBackDrp) {
              this.isDialogBoxOpen = true
              this.isBackDropPopUpOpen = data.fromBackDrp;
              this.transactionService.discardTransactionSlip(resp => { })
            }
          })
          // this.itemInfo.openDialogBackDrp(false);
        }
      }
    })
  }
  /**
   * This method is used to get current url or updateRoute
   */
  getRoute() {
    let currentUrl: string = this.router.url;
    this.updateRoute(currentUrl);
    //console.log(currentUrl);
    // this.displayHeading=this.getDisplayUrl(currentUrl);
    this.routerSubs = this.router.events.pipe().subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        currentUrl = event.url;
        console.log(currentUrl);
        
        this.updateRoute(currentUrl);
        // this.displayHeading=this.getDisplayUrl(currentUrl);
        //console.log(currentUrl);
      }
    });
  }
  /**
   * This method is used to check weather url have child routes or not
   * @param currentUrl current url
   */
  updateRoute(currentUrl: string) {
    if (currentUrl) {
      let urlParts = currentUrl.split(AppConstants.delimiters.urlDelimiter);
      if (urlParts.length > 2) {
        this.isChildPresent = true;
      } else {
        this.isChildPresent = false;
      }
      this.displayHeading = this.getDisplayUrl(currentUrl);
    }
  }

  /**
   * This method is used to show curreny url and Heading of their corresponding component
   * @param currentUrl current url 
   */
  getDisplayUrl(currentUrl): string {
    if (currentUrl.indexOf(AppConstants.routes.tagging) >= 0) {
      if (currentUrl.indexOf(AppConstants.routes.untag) >= 0) {
        return AppConstants.heading.unTag;
        //console.log("done")
      }
      else if (currentUrl.indexOf(AppConstants.routes.newtag) >= 0) {
        return AppConstants.heading.newTag;
      }
      else if (currentUrl.indexOf(AppConstants.routes.oldtag) >= 0) {
        return "Tagging (Old-Tag)";
      }
      else {
        return AppConstants.heading.tagging;
      }
    }
    else if (currentUrl.indexOf(AppConstants.routes.monitoringRfid) >= 0) {
      return AppConstants.heading.monitoringRfid;
    }
    else if (currentUrl.indexOf(AppConstants.routes.databaseQuery) >= 0) {
      return "Database Query";
    }
    else if (currentUrl.indexOf(AppConstants.routes.patron) >= 0) {
      if (currentUrl.indexOf(AppConstants.routes.patronMonitoring) >= 0) {
        return "Patron's Card Monitoring";
      }
      else if (currentUrl.indexOf(AppConstants.routes.patronDatabase) >= 0) {
        return "Patron's Database Query";
      }
      else if (currentUrl.indexOf(AppConstants.routes.patronAccount) >= 0) {
        return "Patron's Account";
      }
      else if (currentUrl.indexOf(AppConstants.routes.patronDetails) >= 0) {
        return "Patron's Account";
      }
      else {
        return "Patron";
      }
    }

    else if (currentUrl.indexOf(AppConstants.routes.checkOut) >= 0) {
      if (this.fromRenew) {
        return "Renew"
      }
      else {
        //console.log(this.fromRenew);
        return "Check-Out";


      }
    }

    else if (currentUrl.indexOf(AppConstants.routes.self) >= 0) {
      if (currentUrl.indexOf(AppConstants.routes.selfCheckout) >= 0) {
        return "Check-Out";
      }
      else if (currentUrl.indexOf(AppConstants.routes.selfCheckin) >= 0) {
        return "Check-in";
      }
      else if (currentUrl.indexOf(AppConstants.routes.selfRenew) >= 0) {
        return "Renew";
      }
      else if (currentUrl.indexOf(AppConstants.routes.myAccount) >= 0) {
        return "My-Account";
      }
      else {
        return "Patron";
      }
    }

    else if (currentUrl.indexOf(AppConstants.routes.biometric) >= 0) {
      if (currentUrl.indexOf(AppConstants.routes.registerFingerPrint) >= 0) {
        return "Biometric Registration";
      }

      else if (currentUrl.indexOf(AppConstants.routes.removeFingerPrint) >= 0) {
        return "Remove Biometric";
      }

      else if (currentUrl.indexOf(AppConstants.routes.verifyFingerPrint) >= 0) {
        return "Biometric Verification";
      }

      else {
        return "Biometric";
      }
    }


    else if (currentUrl.indexOf(AppConstants.routes.fine) >= 0) {
      return "Fine Payment";
    }
    else if (currentUrl.indexOf(AppConstants.routes.settings) >= 0) {
      if (currentUrl.indexOf(AppConstants.routes.options) >= 0) {
        return "Options";
      }
      else {
        return "Settings";
      }
    }
    else if (currentUrl.indexOf(AppConstants.routes.sessionLogs) >= 0) {
      return "Session Log"
    }
    else if (currentUrl.indexOf(AppConstants.routes.checkIn) >= 0) {
      if (this.fromRenew) {
        return "Renew"
      }
      else {
        return "Check-In";

      }
    }

    else if (currentUrl.indexOf(AppConstants.routes.renew) >= 0) {
      return "Renew";
    }

    else {
      return "heading";
    }
  }
  /**
   * This method gets executed when user leave this compoenent
   */
  ngOnDestroy() {
    if (this.routerSubs) {
      this.routerSubs.unsubscribe();
    }
  }
  /**
   * This method used to route the user to dashboard
   */
  routeToDashboard() {
    this.router.navigate(['/dashboard']);
    // this.removeMemberFromAppstate();
    // this.removeRouteFromAppstate();    
  }
  /**
   * Remove a established member
   */
  removeMemberFromAppstate() {
    this.appstate.memberEstablished = undefined;
    this.appStateService.update(this.appstate);
  }
  /**
   * Remove route from app state
   */
  removeRouteFromAppstate() {
    this.appstate.previousRoute = undefined;
    this.appStateService.update(this.appstate);
  }

}
