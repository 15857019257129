import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { Router } from '@angular/router';
import { AppConstants }  from 'src/app/common/constants/app.constants'

/**
 * @class RenewPopUpComponent
 * @author Saurabh bajpai
 */
@Component({
  selector: 'app-renew-pop-up',
  templateUrl : './renew-pop-up.component.html',
  styleUrls: ['./renew-pop-up.component.scss']
})
/**
 * This class used to show weather user want to renew the selected items or not
 */
export class RenewPopUpComponent implements OnInit {
  /**
   * Dialog to pop up the component
   */
  dialog: LsMdDialog<RenewPopUpComponent> | any
  /**
   * Used to store data
   */
  dataList ;
  /**
   * Instance of AppConstant class
   */
  constants: AppConstants; 
  /**
   * A perameter which is used to send the signal that user either want to renew the items or not
   * processRenew is set to on click on 'Yes' button its mean user want to renew the selected items 
   */
  processRenew: boolean;

  /**
   * @constructor function
   * @param router Router service gets injected into this component
   */
  constructor(
    private router:Router
  ) { }

  /**
   * This method gets executed on the loading of the component
   */
  ngOnInit() {
    //console.log("Pop up response");
  }
  /**
   * This method executed on the click on 'No' button.
   */
  cancelButton(){
    this.processRenew = false;
    let data = {
      processrenew: this.processRenew
    }
    this.dialog.close(data);
  }
  /**
   * This method executed on the click on 'Yes' button
   */
  RenewSelectedItems(){
    this.processRenew = true;
    let data = {
      processrenew: this.processRenew
    }
    this.dialog.close(data);
  }

}
