import { Directive, EventEmitter, HostListener, ElementRef, Output, OnInit } from '@angular/core';

@Directive({
  selector: '[LsDropFile]'
})
export class LsDropFileDirective {
  @HostListener('dragover', ['$event']) public onDrag(event) {
    event.preventDefault();
    event.stopPropagation();
    this.ref.nativeElement.style.border = "2px dashed grey";


  }
  @HostListener('dragleave', ['$event']) public onLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.ref.nativeElement.style.border = "none";

  }
  @HostListener('drop', ['$event']) public onDropFile(event) {
    event.preventDefault();
    event.stopPropagation();
    let files = event.dataTransfer.files;
    this.dragFileList.emit(files);
    this.ref.nativeElement.style.border = "none";

  }
  @Output('dragFileList') dragFileList = new EventEmitter<any>();
  constructor(private ref?: ElementRef) { }
  ngOnInit() {
  }
}
