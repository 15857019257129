import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../service/transaction.service';
import { LsMdDialog } from 'src/LSNG/components/ls-dialog/ls-dialog.service';
import { Operations } from '../../model/operations.enum';
import { RFIDLayerService } from '../../service/rfidLayer.service';
import { ItemInfoRFIDService } from '../../service/itemInfoRFID.service';

@Component({
  selector: 'app-back-drop-pop-up',
  templateUrl: './back-drop-pop-up.component.html',
  styleUrls: ['./back-drop-pop-up.component.scss']
})
export class BackDropPopUpComponent implements OnInit {

  headerList: Array<any> = ['Item Id', 'Title', 'Type', 'Status', 'Member Id'];
  headerWidth = ['10', '43', '10', '24', '13'];
  dialog: LsMdDialog<BackDropPopUpComponent>;
  dataList: any;
  contentArray: Array<any> = [];

  constructor(private transactionService: TransactionService,
    private rfidLayerService: RFIDLayerService,
    private itemInfo: ItemInfoRFIDService) { }

  ngOnInit(): void {
    this.itemInfo.getBackDropDataObs.subscribe(data => {
      if (data && data.length) {
        data.forEach(el => {
          let isExitInArr : boolean = false;
          this.contentArray.forEach(elem => {
            if (elem.item_identifier === el.item_identifier) {
             isExitInArr = true;
            } 
          })
          
          if(!isExitInArr){
            this.contentArray.push(el);
          }
        })
      }
    })

    setTimeout(() => {
      this.cancel();
    },1000 * 40);

  }

  cancel() {
    let data = { fromBackDrp: true }
    this.dialog.close(data);
  }

  printSlip() {
    let type = Operations.check_in;
    this.transactionService.transactionSlip(type, resp => {
      // console.log(resp);
      // this.dialog.close(undefined);
      if (resp.data) {
        resp.data.forEach(element => {
          this.rfidLayerService.rfidLayerBookStatus(element, resp => {
            // console.log(resp, 'print');
          })
        });
      }
    })
  }

  ngOnDestroy() {
    let data = { fromBackDrp: true }
    this.dialog.close(data);
  }
}
