import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboComponent } from './combo/combo.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule, MatSelectModule
  ],
  exports: [ComboComponent],
  declarations: [ComboComponent]
})
export class LsComboModule1 { }
