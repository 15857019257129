import { Component, OnInit, Input, EventEmitter, Output, forwardRef } from '@angular/core';
import { NgModule } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ls-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboComponent),
    multi: true
  }]
})
export class ComboComponent implements OnInit, ControlValueAccessor {
  @Input('defaultOptions') optionlist;
  @Input('placeholder') placeholder: String;
  @Input('disabled') disabled: boolean;
  @Input('multiple') multiple: boolean;
  @Input('options') options: Array<any>;
  @Output() updatedList = new EventEmitter<any>();
  value;
  as: string;
  constructor() { }

  writeValue(value: any) {
    if (value !== undefined && value !== null && value instanceof Array) {
      this.optionlist = [];
      for (var i = 0; i < value.length; i++)
        this.optionlist.push(this.options[value[i]].value);
      if (!this.multiple) {
        let a: String = this.optionlist[0];
        this.optionlist = "";
        this.optionlist = a;
      }
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
  onchange(lsitr) {
    var list: Array<number> = new Array<number>();
    if (this.multiple) {
      for (var i = 0; i < lsitr.length; i++) {

        for (var j = 0; j < this.options.length; j++) {
          if (this.options[j].value == lsitr[i])
            list.push(j);
        }
      }
    }
    else {
      for (let j = 0; j < this.options.length; j++) {
        if (this.options[j].value == lsitr)
          list.push(j);
      }
    }
    this.propagateChange(list);
    this.updatedList.emit(list);
  }

  ngOnInit() {
    if (this.multiple == true) {
      var notString = true;
      if (this.optionlist instanceof Array) {
        this.optionlist.forEach(function (item) {
          if (typeof item !== 'string')
            notString = false;
        })
      }
      if (!notString) {
        this.optionlist = [];
      }
    }
    else {
      if (!((typeof this.optionlist) === 'string')) {
        this.optionlist = '';
      }
    }
  }

}
