import { Component } from '@angular/core';
import { AppState } from './common/service/app-state';
import { AppStateService } from './common/service/appState.service';
import { MenuComponent } from './common/components/menu/menu.component';
import { PopupConfig } from './common/components/pop-up/pop-up.component';
import { AppConstants } from './common/constants/app.constants';
import { Router } from '@angular/router';
import { LoginService } from './common/service/login.service';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * Main componentation
 * @class Root component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // libraryName = "Gyanodaya";
  // libraryTagline = "The Learning Resource Centre";
  popUpConfig: PopupConfig = new PopupConfig();
  options = MenuComponent;
  toOpen = MenuComponent; //loads MenuComponent in menu
  constants = AppConstants;
  appStateSubscription;
  appState: AppState = new AppState();
  initialData: any;
  instituteLogo: any;
  displayUserId:string='';
  constructor(
    private appStateService: AppStateService,
    private loginService: LoginService,
    private router:Router,
    private sanitizer : DomSanitizer
  ) { }

  ngOnInit() {
    this.getAppState();
    this.initializeMenuPopupConfig();
    // let user_=this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.userType);
    // if( user_ == AppConstants.userType.self){
    //   localStorage.removeItem(AppConstants.localStorageItems.loginResponse)
    //   this.router.navigate['/login'];
    // }
    this.initialData = JSON.parse(localStorage.getItem('inStng'));
    let objectUrl = 'data:image/png;base64,' + this.initialData.instituteLogo;
    this.instituteLogo = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
  }

  getAppState() {
    this.appStateSubscription = this.appStateService.appStateModel.subscribe((data: AppState) => {
      this.appState = data;
      if(this.appState){
        if(this.appState.loginResponse && this.appState.loginResponse['screen_msg']){
          this.displayUserId = this.appState.loginResponse['screen_msg'];
        }else{
         this.displayUserId = this.appState.displayUserId ;
        }
      }            
    });
  }

  // goToHomepage(){
  //   // first clear logged-in user details from local-storage
  //   this.loginService.adminExitActions();
  //   this.router.navigate(['']);
  // }

  goToDashboard(){
    this.router.navigate([this.constants.routes.dashboard]);
  }

  initializeMenuPopupConfig() {
    this.popUpConfig.hasBackdrop = true;
    this.popUpConfig.backdropClass = "dark-backdrop";
    this.popUpConfig.panelClass = "ls-popup-1";
    this.popUpConfig.positionType = "bottom";
  }

  ngOnDestroy() {
    if (this.appStateSubscription) { 
      this.appStateSubscription.unsubscribe();
    }
  }

}
