import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeSlotAlter'
})
export class TimeSlotAlterPipe implements PipeTransform {

  transform(timeString: any, args?: any): any {
    let titleTime: string;
    if (timeString) {
      let timeArray = timeString.split(" ");
      let subTimeArr = timeArray[0].split(':');
      if (subTimeArr[1] && subTimeArr[1] == '00') {
        if (parseFloat(subTimeArr[0]) < 10) {
          titleTime = parseFloat(subTimeArr[0]) + "";
        }
        else {
          titleTime = subTimeArr[0];
        }
      }
      else {
        titleTime = timeArray[0];//slotstarttime modification
      }
      titleTime = titleTime + " " + timeArray[1];
    }
    return titleTime;
  }
}
