import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
@NgModule({
  exports: [CheckboxComponent],
  imports: [
    CommonModule, MatCheckboxModule, FormsModule
    , ReactiveFormsModule
  ],
  declarations: [CheckboxComponent]
})
export class LsCheckboxModule { }
