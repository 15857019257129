import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable, observable } from 'rxjs';
import { CheckOutComponent } from 'src/app/modules/features/check-out/check-out.component';
import { TransactionService } from './transaction.service';
import { OperationsEnumMap, Operations } from '../model/operations.enum';
import { RFIDLayerService } from './rfidLayer.service';
import { AppStateService } from './appState.service';
import { AppConstants } from '../constants/app.constants';

/**
 * @class CheckoutDeactivateGuard
 * @author Saurabh bajpai
 */
@Injectable({
  providedIn: 'root'
})
export class CheckoutDeactivateGuard implements CanDeactivate<CheckOutComponent> {
  /**
   * Array used to store the current splited url
   */
  arr: Array<any> = [];
  /**
   * Array used to store transaction slip Data
   */
  transactionResp: Array<any> = [];
  automaticPrint: boolean;
  getInitialValueFromStorage: any;
  checkOutCount: number;
  checkInCount: number;
  renewCount: number;
  /**
   * @param transactionService TranscationService will be injected into the component
   */
  constructor(
    private transactionService: TransactionService,
    private rfidlayerService: RFIDLayerService,
    private appStateService: AppStateService
  ) {
    let initialResp = this.appStateService.getItemFromLocalStorage(AppConstants.localStorageItems.initialSettings, true);
    this.automaticPrint = initialResp.automaticPrint;

    this.getInitialValueFromStorage = JSON.parse(localStorage.getItem('inStng'));
    this.checkOutCount = this.getInitialValueFromStorage.checkOutCount;
    this.checkInCount = this.getInitialValueFromStorage.checkInCount;
    this.renewCount = this.getInitialValueFromStorage.renewCount;

  }
  /**
   * This method used to print tracsaction slip on different modes as givn below
   * @param component component name
   * @param currentRoute current route
   * @param currentState current state 
   * @param nextState next state
   */
  canDeactivate(component: CheckOutComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      let url = currentState.url.split('?')[0];
      var arr = url.split('/')
      let paramOperations
      if (!currentState.url.includes('renew')) {
        switch (arr[2]) {
          case 'check-out':
            paramOperations = Operations.check_out

            break;
          case 'check-in':
            paramOperations = Operations.check_in

            break;
          case 'renew':
            paramOperations = Operations.renew
            break;
        }
      } else if (currentState.url.includes('renew=true')) {
        paramOperations = Operations.renew
      }
      // console.log(this.automaticPrint && paramOperations == Operations.check_out && this.checkOutCount);
      // console.log(this.automaticPrint && paramOperations == Operations.check_in && this.checkInCount);
      // console.log(this.automaticPrint && paramOperations == Operations.renew && this.renewCount);


      if ((this.automaticPrint && paramOperations == Operations.check_out && this.checkOutCount) || (this.automaticPrint && paramOperations == Operations.check_in && this.checkInCount) || (this.automaticPrint && paramOperations == Operations.renew && this.renewCount)) {

        this.transactionService.transactionSlip(paramOperations, (resp) => {
          console.log("Transaction service integrated", resp);
          if (resp.data) {
            this.transactionResp = resp.data
            this.transactionResp.forEach(element => {
              this.rfidlayerService.rfidLayerBookStatus(element, (resp) => {
              })
            });
          }
          resolve(true);
        })
      } else {
          resolve(true);
      }
    })
  }
}
