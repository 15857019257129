<div *ngIf="imageDrop==1">
    <canvas #myCanvas [style.display]="canvasDisplay"></canvas><br>
    <input type="file" id="lsimageupload" style="display:none" (change)="selectImage()" accept="image/*" />
    <button mat-raised-button color="primary" (click)="onClick()"><i class="fa fa-cloud-upload"></i>Select</button>
    <button mat-raised-button color="primary" [style.visibility]="buttonHide" (click)="upload()"><i
            class="fa fa-cloud-upload"></i>Upload</button>
</div>
<div *ngIf="imageDrop==2">
    <canvas #dropCanvas *ngIf="true" style="border:0.25px solid" LsDropFile
        (dragFileList)="uploadDragFiles($event)"></canvas>
    <br>
    <button mat-raised-button color="primary" [style.visibility]="buttonHide" (click)="upload()"><i
            class="fa fa-cloud-upload"></i>Upload</button>
</div>
<div *ngIf="imageDrop==3">
    <canvas #myCanvas (click)="onClick()" [style.width]="width" [style.height]="height"
        style=" border-radius : 50% ; border : 1px solid black"></canvas><br>
    <input type="file" id="lsimageupload" style="display:none" (change)="selectImage()" accept="image/*" />
    <!-- <button mat-raised-button color="primary" (click)="onClick()"><i class="fa fa-cloud-upload"></i>Select</button>
    <button mat-raised-button color="primary" [style.visibility]="buttonHide" (click)="upload()"><i class="fa fa-cloud-upload"></i>Upload</button> -->
</div>
<div *ngIf="imageDrop == 4" (click)="onClick()">
    <input type="file" accept="image*" id="lsimageupload" style="display:none" (change)="selectImage()"
        accept="image/*" />
    <ng-content></ng-content>
</div>